import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { DealStatusEnumType } from '__generated__/graphql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import Badge from 'primitives/Badge';
import Button from 'primitives/Button';
import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

import { formatCurrency } from 'utils/format-helper';

import CreateDealExternalInvestorDialogButton from './CreateDealExternalInvestor';
import RemoveExternalInvestorDialogButton from './RemoveExternalInvestorDialogButton';
import UpdateDealExternalInvestorDialog from './UpdateExternalInvestorDialog';

const OTHER_INVESTORS_QUERY = gql(`
  query OtherInvestorsOfDeal($id: ID!) {
    deal(id: $id) {
      id
      status
      dealTerms {
        id
        hasPreviouslyRaisedMoney
        previouslyRaisedAmount
        pastFinancingNote
      }
      externalInvestors {
        id
        name
        description
        amount
        isLead
      }
    }
  }
`);

const UPDATE_DEAL_TERMS_MUTATION = gql(`
  mutation UpdateDealTerms(
    $dealId: ID!
    $hasPreviouslyRaisedMoney: Boolean!
    $previouslyRaisedAmount: Float
    $pastFinancingNote: String
  ) {
    updateDealTerms(
      id: $dealId
      hasPreviouslyRaisedMoney: $hasPreviouslyRaisedMoney
      previouslyRaisedAmount: $previouslyRaisedAmount
      pastFinancingNote: $pastFinancingNote
    ) {
      id
      hasPreviouslyRaisedMoney
      previouslyRaisedAmount
      pastFinancingNote
    }
  }
`);

const DealOtherInvestors: FC = () => {
  const [externalInvestorOnDisplay, setExternalInvestorOnDisplay] = useState<string | null>(null);
  const { dealId } = useParams<{ dealId: string }>() as { dealId: string };

  const { loading, error, data, refetch } = useQuery(OTHER_INVESTORS_QUERY, {
    variables: {
      id: dealId,
    },
  });

  const [updateDealTerms, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_DEAL_TERMS_MUTATION
  );

  if (loading) return <LoadingIndicator />;

  if (error || !data || !dealId) return <ErrorMessage error={error} refetch={refetch} />;

  const { externalInvestors, dealTerms, status } = data.deal;

  function disableEdit() {
    return [
      DealStatusEnumType.Cancelled,
      DealStatusEnumType.Closed,
      DealStatusEnumType.Deleted,
      DealStatusEnumType.Exited,
      DealStatusEnumType.Wired,
    ].includes(status);
  }

  return (
    <div className="border border-gray-200 rounded-lg p-4">
      <div className="mb-4 rounded-lg grid grid-cols-1 gap-2">
        <div>
          <h3 className="text-base font-semibold">External Investors</h3>
          <p className="text-md text-gray-500">
            List the external investors participating in this deal
          </p>
        </div>
        {externalInvestors.map(externalInvestor => (
          <div key={externalInvestor.id}>
            <div className="bg-white ring-1 ring-gray-200 rounded-lg px-4 py-2">
              <div className="flex items-start">
                <div className="flex-1">
                  <h3 className="text-md font-medium">{externalInvestor.name}</h3>
                  <p className="text-sm text-gray-500">{externalInvestor.description}</p>
                </div>
                <div className="flex flex-col justify-start">
                  <p className="text-md text-indigo-500">
                    {externalInvestor.amount && `${formatCurrency(externalInvestor.amount)}`}
                  </p>
                  <p className="text-right text-sm text-gray-500">
                    {externalInvestor.isLead ? <Badge label="Lead" color="purple" /> : ''}
                  </p>
                </div>
              </div>
              {!disableEdit() && (
                <>
                  <div className="mt-4 flex gap-x-2 border-t pt-1">
                    <Button
                      variant="text"
                      className="!text-gray-700 hover:!text-gray-500"
                      onClick={() => setExternalInvestorOnDisplay(externalInvestor.id)}
                    >
                      Edit
                    </Button>
                    <RemoveExternalInvestorDialogButton id={externalInvestor.id} />
                  </div>
                  {externalInvestorOnDisplay === externalInvestor.id && (
                    <UpdateDealExternalInvestorDialog
                      key={externalInvestor.id}
                      id={externalInvestor.id}
                      name={externalInvestor.name}
                      description={externalInvestor.description}
                      amount={externalInvestor.amount}
                      isLead={externalInvestor.isLead}
                      onClose={() => setExternalInvestorOnDisplay(null)}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        ))}
        {!disableEdit() && (
          <div>
            <CreateDealExternalInvestorDialogButton dealId={dealId} />
          </div>
        )}
      </div>

      <FormPanelWithReadMode
        loading={updateLoading}
        error={updateError}
        onSubmit={submitData => {
          updateDealTerms({
            variables: {
              dealId,
              hasPreviouslyRaisedMoney: submitData.hasPreviouslyRaisedMoney === 'true',
              previouslyRaisedAmount:
                submitData.hasPreviouslyRaisedMoney === 'true'
                  ? submitData.previouslyRaisedAmount
                  : 0,
              pastFinancingNote:
                submitData.hasPreviouslyRaisedMoney === 'true'
                  ? submitData.pastFinancingNote
                  : null,
            },
          });
        }}
        disableEdit={disableEdit()}
      >
        <FormInput
          fullWidth
          type="radio-group"
          fieldName="hasPreviouslyRaisedMoney"
          label="Has the company previously raised money?"
          defaultValue={dealTerms?.hasPreviouslyRaisedMoney?.toString() || ''}
          options={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          validators={{
            required: true,
          }}
        />
        <FormInput
          fullWidth
          type="currency"
          fieldName="previouslyRaisedAmount"
          label="Previously raised amount"
          helperText="The total amount the company has raised in previous rounds"
          defaultValue={dealTerms?.previouslyRaisedAmount || ''}
          conditionsToShow={{
            matches: [
              {
                field: 'hasPreviouslyRaisedMoney',
                condition: '===',
                value: 'true',
              },
            ],
          }}
          validators={{
            required: true,
          }}
        />
        <FormInput
          fullWidth
          type="textarea"
          fieldName="pastFinancingNote"
          label="Past financing note"
          helperText="A description of the company's past financing rounds"
          defaultValue={dealTerms?.pastFinancingNote || ''}
          conditionsToShow={{
            matches: [
              {
                field: 'hasPreviouslyRaisedMoney',
                condition: '===',
                value: 'true',
              },
            ],
          }}
          validators={{
            required: true,
          }}
        />
      </FormPanelWithReadMode>
    </div>
  );
};

export default DealOtherInvestors;
