import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DetailsPanel from 'components/DetailsPanel';
import ErrorMessage from 'components/ErrorMessage';

import DeleteUserButton from './DeleteUserButton';
import UpdateUser from './UpdateUser';
import UpdateUserRole from './UpdateUserRole';
import UpdateUserStatus from './UpdateUserStatus';

const USER_QUERY = gql(`
  query getUserInformation($userId: ID!) {
    user(id: $userId) {
      id
      oldPlatformId
      name
      email
      phoneNumber
      countryCode
      status
      role
    }
  }
`);

const UserInformation: FC = () => {
  const { userId } = useParams<{ userId: string }>() as { userId: string };

  const { loading, error, data, refetch } = useQuery(USER_QUERY, {
    variables: {
      userId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const user = data.user;

  return (
    <>
      <DetailsPanel
        data={[
          {
            label: 'Old Platform ID',
            value: user.oldPlatformId,
          },
        ]}
      />
      <UpdateUser
        id={user.id}
        name={user.name}
        email={user.email}
        phoneNumber={user.phoneNumber}
        countryCode={user.countryCode}
      />
      <UpdateUserStatus id={user.id} status={user.status} />
      <UpdateUserRole id={user.id} role={user.role} />
      <DeleteUserButton id={user.id} />
    </>
  );
};

export default UserInformation;
