import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import bankIcon from 'media/icons/bank.svg';
import dematIcon from 'media/icons/demat-account.svg';
import documentsIcon from 'media/icons/documents.svg';
import applicationIcon from 'media/icons/incoming-applications.svg';
import informationIcon from 'media/icons/information.svg';
import investmentIcon from 'media/icons/investment.svg';
import kycIcon from 'media/icons/kyc.svg';

const INVESTMENT_ENTITY_QUERY = gql(`
  query getInvestmentEntityName($id: ID!) {
    investmentEntity(id: $id) {
      id
      name
    }
  }
`);

const InvestmentEntityPage: FC = () => {
  const navigate = useNavigate();
  const { investmentEntityId } = useParams<{ investmentEntityId: string }>() as {
    investmentEntityId: string;
  };

  const { loading, error, data, refetch } = useQuery(INVESTMENT_ENTITY_QUERY, {
    variables: {
      id: investmentEntityId,
    },
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const investmentEntity = data.investmentEntity;

    const tabs = [
      {
        key: 'information',
        label: 'Information',
        description: 'Basic details',
        icon: informationIcon,
        route: `/investment-entities/${investmentEntityId}`,
        exact: true,
      },
      {
        key: 'kyc',
        label: 'KYC',
        description: 'Know Your Customer',
        icon: kycIcon,
        route: `/investment-entities/${investmentEntityId}/kyc`,
      },
      {
        key: 'demat-account',
        label: 'Demat Account',
        description: 'View and manage demat account',
        route: `/investment-entities/${investmentEntityId}/demat`,
        icon: dematIcon,
      },
      {
        key: 'document',
        label: 'Documents',
        description: 'Investment entity documents',
        icon: documentsIcon,
        route: `/investment-entities/${investmentEntityId}/documents`,
      },
      {
        key: 'investor-application',
        label: 'Investor Application',
        description: 'Investor application details',
        icon: applicationIcon,
        route: `/investment-entities/${investmentEntityId}/investor-application`,
      },
      {
        key: 'bank-accounts',
        label: 'Bank Accounts',
        description: 'Bank account details',
        icon: bankIcon,
        route: `/investment-entities/${investmentEntityId}/bank-accounts`,
      },
      {
        key: 'investments',
        label: 'Investments',
        description: 'Investment details',
        icon: investmentIcon,
        route: `/investment-entities/${investmentEntityId}/investments`,
      },
    ];

    return (
      <>
        <SidebarLayoutNavigation
          title={investmentEntity.name}
          subTitle="Investment Entity"
          onBackButtonClick={() => navigate(-1)}
        />
        <SidebarLayoutContent secondaryTabs={tabs}>
          <Outlet />
        </SidebarLayoutContent>
      </>
    );
  };

  return <SidebarLayout>{renderContent()}</SidebarLayout>;
};

export default InvestmentEntityPage;
