import { FC, useState } from 'react';

import TextField from 'primitives/TextField';

const PercentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m8.99 14.993 6-6m6 3.001c0 1.268-.63 2.39-1.593 3.069a3.746 3.746 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043 3.745 3.745 0 0 1-3.068 1.593c-1.268 0-2.39-.63-3.068-1.593a3.745 3.745 0 0 1-3.296-1.043 3.746 3.746 0 0 1-1.043-3.297 3.746 3.746 0 0 1-1.593-3.068c0-1.268.63-2.39 1.593-3.068a3.746 3.746 0 0 1 1.043-3.297 3.745 3.745 0 0 1 3.296-1.042 3.745 3.745 0 0 1 3.068-1.594c1.268 0 2.39.63 3.068 1.593a3.745 3.745 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.297 3.746 3.746 0 0 1 1.593 3.068ZM9.74 9.743h.008v.007H9.74v-.007Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm4.125 4.5h.008v.008h-.008v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
      />
    </svg>
  );
};

const SetupFeeEstimator: FC<{ amount: number }> = ({ amount: closingAmount }) => {
  const [amount, setAmount] = useState<number>();

  return (
    <div className="w-full col-span-full">
      <TextField
        onChange={e => {
          console.log(e.target.value);
          setAmount(closingAmount * (Number(e.target.value) / 100));
        }}
        label="Estimate setup fee"
        placeholder="Enter percentage"
        type="number"
        leadingIcon={<PercentIcon />}
      />
      {amount ? (
        <p className="text-sm mt-2 text-gray-400">Setup fee: ₹{amount.toFixed(2)}</p>
      ) : null}
    </div>
  );
};

export default SetupFeeEstimator;
