import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { InvestmentEntityDematStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';
import TextField from 'primitives/TextField';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import FilterPanel from 'components/FilterPanel';

import debounce from 'utils/debounce';

const DEMAT_INFORMATION_QUERY = gql(`
  query fetchDematInformation($cursor: ID, $limit: Int, $filters: InvestmentEntitiesFilterType) {
    investmentEntities(cursor: $cursor, limit: $limit, filters: $filters) {
      nodes {
        id
        name
        user {
          id
          name
        }
        status
        type
        dematAccount {
          id
          status
        }
      }
      pageInfo {
        hasNextPage
        cursor
        totalCount
      }
    }
  }
`);

const DematInformationPage: FC = () => {
  const navigate = useNavigate();
  const { loading, error, data, refetch, fetchMore, networkStatus, variables } = useQuery(
    DEMAT_INFORMATION_QUERY,
    {
      variables: {
        limit: 50,
        filters: undefined,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const renderContent = () => {
    if (loading && !data) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const investmentEntities = data.investmentEntities.nodes;
    const pageInfo = data.investmentEntities.pageInfo;

    const filters = [
      {
        type: 'dematStatus',
        label: 'Status',
        options: [
          {
            label: 'All',
            value: undefined,
          },
          {
            label: 'Pending',
            value: InvestmentEntityDematStatusEnumType.Pending,
          },
          {
            label: 'CML Document pending',
            value: InvestmentEntityDematStatusEnumType.CmlDocumentPending,
          },
          {
            label: 'Needs attention',
            value: InvestmentEntityDematStatusEnumType.NeedsAttention,
          },
          {
            label: 'Submitted',
            value: InvestmentEntityDematStatusEnumType.Submitted,
          },
        ],
      },
    ];

    return (
      <>
        <FilterPanel activeFilters={variables?.filters} filters={filters} refetch={refetch} />
        <div className="flex justify-between items-end">
          <TextField
            placeholder="Search for a user"
            onChange={debounce(e => {
              refetch({
                filters: {
                  ...variables?.filters,
                  text: e.target.value,
                },
              });
            }, 500)}
          />
          <span className="text-sm text-gray-500">
            {investmentEntities.length} of {pageInfo.totalCount} members
          </span>
        </div>
        <DataTable
          data={investmentEntities}
          onClick={investmentEntity => navigate(`/investment-entities/${investmentEntity.id}`)}
          columns={[
            {
              label: 'Select',
              fieldName: 'id',
              type: 'SELECT',
            },
            {
              label: 'Name',
              fieldName: 'name',
            },
            {
              label: 'User',
              fieldName: 'user.name',
            },
            {
              label: 'Status',
              fieldName: 'status',
              type: 'STATUS',
            },
            {
              label: 'Type',
              fieldName: 'type',
              type: 'INVESTMENT_ENTITY_TYPE',
            },
          ]}
          hasNextPage={pageInfo.hasNextPage}
          filterLoading={networkStatus === NetworkStatus.setVariables}
          paginationLoading={loading}
          onLoadMore={() =>
            fetchMore({
              variables: {
                cursor: pageInfo.cursor,
              },
            })
          }
        />
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Demat Information"
        subTitle="View demat information submitted by investors"
      />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default DematInformationPage;
