import { DistributionStatusEnumType } from '__generated__/graphql';

const distributionStatusToReadable = (
  status: DistributionStatusEnumType
): {
  label: string;
  color: string;
} => {
  switch (status) {
    case DistributionStatusEnumType.Active:
      return {
        label: 'Active',
        color: 'green',
      };
    case DistributionStatusEnumType.Inactive:
      return {
        label: 'Inactive',
        color: 'red',
      };
    default:
      return {
        label: 'Unknown',
        color: 'gray',
      };
  }
};

export default distributionStatusToReadable;
