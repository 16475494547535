import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import LoadingIndicator from 'primitives/LoadingIndicator';
import SlideOver from 'primitives/SlideOver';

import DetailsPanel from 'components/DetailsPanel';
import ErrorMessage from 'components/ErrorMessage';

import ClosingAudit from './ClosingAudit';
import ClosingEmailAudit from './ClosingEmailAudit';
import DeleteClosingButton from './DeleteClosingButton';
import SendDrawdownNoticeButton from './SendDrawdownNoticeButton';
import SendPaymentInformationButton from './SendPaymentInformationButton';
import SendPaymentReminderButton from './SendPaymentReminderButton';
import UpdateClosing from './UpdateClosing';
import UpdateClosingSetupFee from './UpdateClosingSetupFee';
import UpdateClosingStatus from './UpdateClosingStatus';

const CLOSING_QUERY = gql(`
  query getClosing($id: ID!) {
    closing(id: $id) {
      id
      oldPlatformId
      amount
      fundingSource
      investmentEntity {
        id
        name
        user {
          id
          email
        }
      }
      status
      wiredAt
      executedAt
      finalisedAt
      setupFee
      createdAt
      updatedAt
    }
  }
`);

const ClosingSlideOver: FC<{
  open: boolean;
  closingId: string;
  onClose: () => void;
}> = ({ open = false, closingId, onClose }) => {
  const { loading, error, data, refetch } = useQuery(CLOSING_QUERY, {
    variables: {
      id: closingId,
    },
    skip: !open,
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const closing = data.closing;

    return (
      <>
        <DetailsPanel
          data={[
            {
              label: 'Old Platform ID',
              value: closing.oldPlatformId,
            },
            {
              label: 'Email ID',
              value: closing.investmentEntity.user.email,
            },
          ]}
        />
        <UpdateClosing
          id={closing.id}
          amount={closing.amount}
          fundingSource={closing.fundingSource}
          investmentEntity={closing.investmentEntity}
        />
        <UpdateClosingStatus
          id={closing.id}
          status={closing.status}
          wiredAt={closing.wiredAt}
          executedAt={closing.executedAt}
          finalisedAt={closing.finalisedAt}
        />
        <UpdateClosingSetupFee
          id={closing.id}
          amount={closing.amount}
          setupFee={closing.setupFee}
        />
        {closing.status !== 'ACTIVE' ? (
          <>
            <DeleteClosingButton id={closing.id} onDelete={onClose} />
            <SendDrawdownNoticeButton id={closing.id} />
            <SendPaymentInformationButton id={closing.id} />
            <SendPaymentReminderButton id={closing.id} />
          </>
        ) : null}

        <ClosingEmailAudit closingId={closing.id} />
        <ClosingAudit closingId={closing.id} />
      </>
    );
  };

  return (
    <SlideOver
      open={open}
      onClose={onClose}
      title="Closing"
      subTitle="Manage and audit closing details"
    >
      {renderContent()}
    </SlideOver>
  );
};

export default ClosingSlideOver;
