import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { ClosingStatusEnumType, DealStatusEnumType } from '__generated__/graphql';
import dayjs from 'dayjs';
import { FC, useState } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';
import EditorFormInput from 'components/QuillEditor/EditorFormInput';

const UPDATE_POST_MUTATION = gql(`
  mutation UpdatePostOnDeal($id: ID!, $title: String!, $message: String!, $dealId: ID, $targetClosingStatuses: [ClosingStatusEnumType!]) {
    updatePost(id: $id, title: $title, message: $message, dealId: $dealId, targetClosingStatuses: $targetClosingStatuses) {
      id
      status
      title
      message
      deal {
        id
        companyInformation {
          id
          company {
            name
          }
        }
      }
      createdAt
      targetClosingStatuses
    }
  }
`);

const UpdatePost: FC<{
  id: string;
  title: string;
  message: string;
  createdAt: string;
  deal?:
    | {
        id: string;
        status: DealStatusEnumType;
      }
    | null
    | undefined;
  targetClosingStatuses?: ClosingStatusEnumType[] | null | undefined;
  isBeingEdited?: (boolean) => void;
}> = ({ id, title, message, createdAt, deal, targetClosingStatuses, isBeingEdited }) => {
  const [updatePost, { loading, error }] = useMutation(UPDATE_POST_MUTATION, {
    refetchQueries: ['getPost'],
  });
  const [targetAudienceWarning, setTargetAudienceWarning] = useState(
    !targetClosingStatuses || targetClosingStatuses.length === 0
  );
  const [targetAudienceError, showTargetAudienceError] = useState(false);
  const targetAudienceOptions = [
    { label: 'All LPs of this Syndicate', value: 'SYNDICATE_USERS' },
    { label: 'Only the investors in this deal', value: 'CLOSINGS' },
  ];
  const targetAudienceOptionsToShow = targetAudienceOptions.filter(option => {
    if (!deal) return option.value !== 'CLOSINGS';
    return option;
  });

  return (
    <>
      <FormPanelWithReadMode
        loading={loading}
        error={error}
        isInReadOnlyMode={readOnlyMode => isBeingEdited && isBeingEdited(!readOnlyMode)}
        onSubmit={data => {
          showTargetAudienceError(false);
          if (
            data.targetAudience === 'CLOSINGS' &&
            (!data.targetClosingStatuses || data.targetClosingStatuses.length === 0)
          ) {
            showTargetAudienceError(true);
            return;
          }
          updatePost({
            variables: {
              id,
              title: data.title,
              message: data.content,
              dealId: deal ? deal.id : undefined,
              targetClosingStatuses:
                data.targetAudience === 'SYNDICATE_USERS' ? undefined : data.targetClosingStatuses,
            },
          });
        }}
        submitButtonLabel="Save"
      >
        {dayjs(createdAt)
          .startOf('day')
          .isAfter(dayjs('2024-09-11T00:00:00+05:30').startOf('day')) ? (
          <>
            <FormInput
              fullWidth
              type="radio-group"
              fieldName="targetAudience"
              label="Who should see this post?"
              defaultValue={
                targetClosingStatuses && targetClosingStatuses.length > 0
                  ? 'CLOSINGS'
                  : 'SYNDICATE_USERS'
              }
              options={targetAudienceOptionsToShow}
              onChange={value => {
                if (value.targetAudience === 'SYNDICATE_USERS') setTargetAudienceWarning(true);
                else setTargetAudienceWarning(false);
              }}
              validators={{
                required: true,
              }}
            />
            <FormInput
              fullWidth
              type="checkbox-group"
              fieldName="targetClosingStatuses"
              label="Choose investors by stage of investment"
              defaultValue={
                targetClosingStatuses ||
                (deal?.status === DealStatusEnumType.Exited ||
                deal?.status === DealStatusEnumType.Wired
                  ? [ClosingStatusEnumType.Active]
                  : [ClosingStatusEnumType.Committed, ClosingStatusEnumType.Transferred])
              }
              compact
              options={
                deal?.status === DealStatusEnumType.Exited ||
                deal?.status === DealStatusEnumType.Wired
                  ? [{ label: 'Active', value: ClosingStatusEnumType.Active }]
                  : [
                      { label: 'Committed', value: ClosingStatusEnumType.Committed },
                      { label: 'Transferred', value: ClosingStatusEnumType.Transferred },
                    ]
              }
              conditionsToShow={{
                matches: [
                  {
                    field: 'targetAudience',
                    condition: '===',
                    value: 'CLOSINGS',
                  },
                ],
              }}
              validators={{
                required: true,
              }}
            />
          </>
        ) : (
          <></>
        )}
        <FormInput
          fullWidth
          type="text"
          fieldName="title"
          label="Title"
          defaultValue={title}
          validators={{
            required: true,
          }}
        />
        <FormInput
          fullWidth
          type="custom"
          fieldName="content"
          label="Content"
          defaultValue={message}
          customInput={EditorFormInput}
          validators={{
            required: true,
          }}
        />
      </FormPanelWithReadMode>
      {targetAudienceError ? (
        <p className="text-red-500">
          If you want to target the investors in this deal, please select at least one stage of
          investment
        </p>
      ) : null}
      {!deal || targetAudienceWarning ? (
        <p className="col-span-full text-red-500 text-sm mb-8">
          This post will go out to all investors in the syndicate.
        </p>
      ) : null}
    </>
  );
};

export default UpdatePost;
