import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { CountryEnumType, IncorporationTypeEnumType } from '__generated__/graphql';
import dayjs from 'dayjs';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_COMPANY_MUTATION = gql(`
  mutation UpdateCompany(
    $id: ID!
    $name: String!
    $legalName: String!
    $countryOfIncorporation: CountryEnumType!
    $dateOfIncorporation: String
    $incorporationType: IncorporationTypeEnumType!
    $description: String
    $cin: String
    $pan: String
    $website: String
    $image: Upload
  ) {
    updateCompany(
      id: $id
      name: $name
      legalName: $legalName
      countryOfIncorporation: $countryOfIncorporation
      dateOfIncorporation: $dateOfIncorporation
      incorporationType: $incorporationType
      description: $description
      cin: $cin
      pan: $pan
      website: $website
      image: $image
    ) {
      id
      name
      legalName
      countryOfIncorporation
      dateOfIncorporation
      incorporationType
      status
      description
      cin
      pan
      website
      image
      updatedAt
    }
  }
`);

const UpdateCompany: FC<{
  id: string;
  name: string;
  legalName?: string | null;
  countryOfIncorporation?: CountryEnumType | null;
  dateOfIncorporation?: string | null;
  incorporationType?: IncorporationTypeEnumType | null;
  description?: string | null;
  cin?: string | null;
  pan?: string | null;
  website?: string | null;
  image?: string | null;
}> = ({
  id,
  name,
  legalName,
  description,
  countryOfIncorporation,
  dateOfIncorporation,
  cin,
  pan,
  website,
  image,
}) => {
  const [updateCompany, { loading, error }] = useMutation(UPDATE_COMPANY_MUTATION);

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateCompany({
          variables: {
            id,
            name: data.name,
            legalName: data.legalName,
            countryOfIncorporation: data.countryOfIncorporation,
            dateOfIncorporation: data.dateOfIncorporation
              ? dayjs(data.dateOfIncorporation).format('YYYY-MM-DD')
              : null,
            incorporationType: data.incorporationType,
            description: data.description,
            cin: data.cin,
            pan: data.pan,
            website: data.website,
            image: typeof data.image !== 'string' ? data.image : null,
          },
        });
      }}
      title="Information"
    >
      <FormInput type="text" fieldName="name" label="Name" defaultValue={name} />
      <FormInput type="text" fieldName="legalName" label="Legal Name" defaultValue={legalName} />
      <FormInput
        type="select"
        fieldName="countryOfIncorporation"
        label="Country of Incorporation"
        defaultValue={countryOfIncorporation}
        options={[{ value: 'IN', label: 'India' }]}
      />
      <FormInput
        type="date"
        fieldName="dateOfIncorporation"
        label="Date of Incorporation"
        defaultValue={dateOfIncorporation}
      />
      <FormInput
        type="select"
        fieldName="incorporationType"
        label="Incorporation Type"
        options={[
          { value: IncorporationTypeEnumType.PrivateLimited, label: 'Private Limited' },
          { value: IncorporationTypeEnumType.Llc, label: 'LLC' },
          { value: IncorporationTypeEnumType.Other, label: 'Other' },
          { value: IncorporationTypeEnumType.NotSure, label: 'Not Sure' },
        ]}
        defaultValue={IncorporationTypeEnumType.PrivateLimited}
      />
      <FormInput
        type="text"
        fieldName="description"
        label="Description"
        defaultValue={description}
      />
      <FormInput type="text" fieldName="cin" label="CIN" defaultValue={cin} />
      <FormInput type="text" fieldName="pan" label="PAN" defaultValue={pan} />
      <FormInput type="text" fieldName="website" label="Website" defaultValue={website} />
      <FormInput type="upload" fieldName="image" label="Logo" defaultValue={image || null} />
    </FormPanelWithReadMode>
  );
};

export default UpdateCompany;
