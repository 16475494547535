import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';
import SlideOver from 'primitives/SlideOver';

import ErrorMessage from 'components/ErrorMessage';

import RemoveShareValuationChangeButton from './RemoveShareValuationChangeButton';
import UpdateShareValuationChange from './UpdateShareValuationChange';

const SHARE_CLASS_QUERY = gql(`
  query getShareValuationChange($id: ID!, $companyId: ID!) {
    shareValuationChange(compositeId: $id) {
      id
      pricePerShare
      effectiveFrom
      shareClasses {
        id
        name
      }
      createdAt
      updatedAt
      wasConvertedFromUSD
    }
    company(id: $companyId) {
      id
      shareClasses {
        id
        name
      }
    }
  }
`);

const ShareValuationChangeSlideOver: FC<{
  open: boolean;
  shareValuationChangeId: string;
  onClose: () => void;
}> = ({ open = false, shareValuationChangeId, onClose }) => {
  const { companyId } = useParams<{ companyId: string }>() as { companyId: string };
  const { loading, error, data, refetch } = useQuery(SHARE_CLASS_QUERY, {
    variables: {
      id: shareValuationChangeId,
      companyId,
    },
    skip: !open,
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const shareValuationChange = data.shareValuationChange;

    return (
      <>
        <UpdateShareValuationChange
          id={shareValuationChange.id}
          pricePerShare={shareValuationChange.pricePerShare}
          effectiveFrom={shareValuationChange.effectiveFrom}
          shareClasses={shareValuationChange.shareClasses}
          allShareClasses={data.company.shareClasses}
          wasConvertedFromUSD={shareValuationChange.wasConvertedFromUSD}
        />
        <RemoveShareValuationChangeButton id={shareValuationChange.id} onRemove={onClose} />
      </>
    );
  };

  return (
    <SlideOver
      open={open}
      onClose={onClose}
      title="Share Valuation Change"
      subTitle="Manage the share valuation change and the classes it affects"
    >
      {renderContent()}
    </SlideOver>
  );
};

export default ShareValuationChangeSlideOver;
