import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const COMPANY_REDIS_SYNC = gql(`
  mutation AddCompaniesToIndex {
    addCompaniesToIndex
    {
      count
      status
    }
  }
`);

const CompanySyncDialogButton: FC<{}> = () => {
  const [initiateCompanyRedisSync, { loading, error }] = useMutation(COMPANY_REDIS_SYNC);

  return (
    <ConfirmButton
      loading={loading}
      error={error}
      onConfirm={() => initiateCompanyRedisSync()}
      description="This will add all companies to the redis search index."
    >
      Add Companies To Index
    </ConfirmButton>
  );
};

export default CompanySyncDialogButton;
