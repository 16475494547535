import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import dayjs from 'dayjs';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_SHARE_VALUATION_CHANGE_MUTATION = gql(`
  mutation UpdateShareValuationChange(
    $id: ID!
    $pricePerShare: Float!
    $effectiveFrom: String!
    $shareClassIds: [ID!]!
    $wasConvertedFromUSD: Boolean
  ) {
    updateShareValuationChange(
      compositeId: $id
      pricePerShare: $pricePerShare
      effectiveFrom: $effectiveFrom
      shareClassIds: $shareClassIds
      wasConvertedFromUSD: $wasConvertedFromUSD
    ) {
      id
      pricePerShare
      effectiveFrom
      shareClasses {
        id
        name
      }
      updatedAt
      wasConvertedFromUSD
    }
  }
`);

const UpdateShareValuationChange: FC<{
  id: string;
  pricePerShare: number;
  effectiveFrom: string;
  shareClasses: { id: string; name: string }[];
  allShareClasses: { id: string; name: string }[];
  wasConvertedFromUSD: boolean;
}> = ({ id, pricePerShare, effectiveFrom, shareClasses, allShareClasses, wasConvertedFromUSD }) => {
  const [updateShareValuationChange, { loading, error }] = useMutation(
    UPDATE_SHARE_VALUATION_CHANGE_MUTATION,
    {
      refetchQueries: ['fetchAssetsByCompanyId'],
    }
  );

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data =>
        updateShareValuationChange({
          variables: {
            id,
            pricePerShare: data.pricePerShare,
            effectiveFrom: dayjs(data.effectiveFrom).format('YYYY-MM-DD'),
            shareClassIds: data.shareClassIds,
            wasConvertedFromUSD: data.wasConvertedFromUSD,
          },
        })
      }
    >
      <FormInput
        type="checkbox-group"
        fieldName="shareClassIds"
        label="Share Classes"
        defaultValue={shareClasses.map(({ id }) => id)}
        options={allShareClasses.map(({ id, name }) => ({ value: id, label: name }))}
        validators={{ required: true }}
      />
      <FormInput
        type="currency"
        fieldName="pricePerShare"
        label="Price Per Share"
        defaultValue={pricePerShare}
        validators={{ required: true }}
      />
      <FormInput
        type="date"
        fieldName="effectiveFrom"
        label="Effective From"
        defaultValue={effectiveFrom}
        validators={{ required: true }}
      />
      <FormInput
        type="switch"
        fieldName="wasConvertedFromUSD"
        label="Was Converted From USD"
        helperText='This is used to indicate if the share valuation change was converted from USD in the old platform. If this price is verified, switch this to "No".'
        defaultValue={wasConvertedFromUSD}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateShareValuationChange;
