import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { DealStatusEnumType } from '__generated__/graphql';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DetailsPanel from 'components/DetailsPanel';
import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

import SearchCompanies from './SearchCompanies';
import {
  dealTypeOptions,
  financialInstrumentTypeOptions,
  fundingRoundNameOptions,
  premiumToLastRoundTypeOptions,
  prorataRightsTypeOptions,
  shareClassTypeOptions,
  transactionTypeOptions,
  valuationTypeOptions,
} from './constants';

const GET_DEAL_QUERY = gql(`
  query Deal($id: ID!) {
    deal(id: $id) {
      id
      oldPlatformId
      status
      type
      allocation
      closingDate
      schemeName
      createdAt
      updatedAt
      companyInformation {
        id
        company {
          id
          name
          legalName
          image
        }
      }
      syndicate {
        id
      }
      dealTerms {
        id
        transactionType
        fundingRoundName
        valuationType
        valuationOrCap
        financialInstrument
        shareClass
        prorataRights
        minimumInvestment
        otherTerms
        fundingRoundSize
        expectedSharePrice
        premiumToLastRound
        premiumToLastRoundType
      }
    }
  }
`);

const UPSERT_DEAL_MUTATION = gql(`
  mutation UpsertDealAsAdmin(
    $syndicateId: ID!
    $dealId: ID
    $companyId: ID!
    $transactionType: DealTransactionTypeEnumType!
    $fundingRoundName: FundingRoundNameEnumType!
    $fundingRoundSize: Float!
    $type: DealTypeEnumType!
    $valuationOrCap: Float!
    $valuationType: ValuationTypeEnumType!
    $financialInstrument: FinancialInstrumentEnumType!
    $expectedSharePrice: Float
    $premiumToLastRound: Float
    $premiumToLastRoundType: PremiumToLastRoundEnumType
    $shareClass: ShareClassEnumType!
    $allocation: Float!
    $prorataRights: ProrataRightsEnumType!
    $minimumInvestment: Float!
    $closingDate: String!
    $schemeName: String
    $otherTerms: String
  ) {
    upsertDeal(
      syndicateId: $syndicateId
      dealId: $dealId
      companyId: $companyId
      transactionType: $transactionType
      fundingRoundName: $fundingRoundName
      fundingRoundSize: $fundingRoundSize
      type: $type
      valuationOrCap: $valuationOrCap
      valuationType: $valuationType
      financialInstrument: $financialInstrument
      expectedSharePrice: $expectedSharePrice
      premiumToLastRound: $premiumToLastRound
      premiumToLastRoundType: $premiumToLastRoundType
      shareClass: $shareClass
      allocation: $allocation
      prorataRights: $prorataRights
      minimumInvestment: $minimumInvestment
      closingDate: $closingDate
      schemeName: $schemeName
      otherTerms: $otherTerms
    ) {
      id
      status
      type
      allocation
      closingDate
      schemeName
      createdAt
      updatedAt
      companyInformation {
        id
        company {
          id
          name
        }
      }
      syndicate {
        id
      }
      dealTerms {
        id
        transactionType
        fundingRoundName
        valuationType
        valuationOrCap
        financialInstrument
        shareClass
        prorataRights
        minimumInvestment
        otherTerms
        fundingRoundSize
        expectedSharePrice
        premiumToLastRound
        premiumToLastRoundType
      }
    }
  }
`);

const BasicInformation: FC = () => {
  const { dealId } = useParams<{ dealId: string }>() as { dealId: string };

  const { data, loading, error, refetch } = useQuery(GET_DEAL_QUERY, {
    variables: {
      id: dealId,
    },
  });

  const [upsertDeal, { loading: upsertLoading, error: upsertError }] =
    useMutation(UPSERT_DEAL_MUTATION);

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const deal = data.deal;

  function disableEdit() {
    if (!data?.deal) return true;

    return [
      DealStatusEnumType.Cancelled,
      DealStatusEnumType.Closed,
      DealStatusEnumType.Deleted,
      DealStatusEnumType.Exited,
      DealStatusEnumType.Wired,
    ].includes(data.deal.status);
  }

  return (
    <>
      <DetailsPanel
        data={[
          {
            label: 'Old Platform ID',
            value: data.deal.oldPlatformId,
          },
        ]}
      />
      <FormPanelWithReadMode
        loading={upsertLoading}
        error={upsertError}
        onSubmit={submitData => {
          upsertDeal({
            variables: {
              dealId,
              syndicateId: data.deal.syndicate.id,
              companyId: submitData.company.id,
              transactionType: submitData.transactionType,
              fundingRoundName: submitData.fundingRoundName,
              fundingRoundSize: submitData.fundingRoundSize,
              financialInstrument: submitData.financialInstrument,
              valuationOrCap: submitData.valuationOrCap,
              valuationType: submitData.valuationType,
              expectedSharePrice: submitData.expectedSharePrice,
              premiumToLastRound: submitData.premiumToLastRound,
              premiumToLastRoundType: submitData.premiumToLastRoundType,
              shareClass: submitData.shareClass,
              allocation: submitData.allocation,
              prorataRights: submitData.prorataRights,
              minimumInvestment: submitData.minimumInvestment,
              closingDate: submitData.closingDate,
              otherTerms: submitData.otherTerms,
              type: submitData.type,
              schemeName: submitData.schemeName,
            },
          });
        }}
        disableEdit={disableEdit()}
      >
        <FormInput
          type="select"
          fieldName="type"
          label="Deal Type"
          defaultValue={data.deal.type}
          options={dealTypeOptions}
          conditionsToShow={{
            matches: [
              {
                field: 'transactionType',
                condition: '===',
                value: 'PRIMARY',
              },
            ],
          }}
        />
        <FormInput
          type="custom"
          fieldName="company"
          label="Company"
          defaultValue={deal.companyInformation.company}
          customInput={SearchCompanies}
          validators={{
            required: true,
          }}
        />
        <FormInput
          fullWidth
          type="select"
          fieldName="transactionType"
          label="Transaction Type"
          defaultValue={deal.dealTerms?.transactionType}
          options={transactionTypeOptions}
          validators={{
            required: true,
          }}
        />
        <FormInput
          fullWidth
          type="select"
          fieldName="fundingRoundName"
          label="Round"
          defaultValue={deal.dealTerms?.fundingRoundName}
          options={fundingRoundNameOptions}
          validators={{
            required: true,
          }}
        />
        <FormInput
          type="currency"
          fieldName="fundingRoundSize"
          label="Round Size"
          helperText="The total amount the company wants to raise this round"
          defaultValue={deal.dealTerms?.fundingRoundSize || undefined}
          validators={{
            required: true,
            minValue: 5000000,
          }}
        />
        <FormInput
          type="currency"
          fieldName="expectedSharePrice"
          label="Expected Share Price"
          helperText="The price per share, including any additional costs, at which the SPV expects to purchase the shares"
          defaultValue={deal.dealTerms?.expectedSharePrice || undefined}
          conditionsToShow={{
            matches: [
              {
                field: 'transactionType',
                condition: '===',
                value: 'SECONDARY',
              },
            ],
          }}
          validators={{
            required: true,
          }}
        />
        <FormInput
          type="number"
          fieldName="premiumToLastRound"
          label="Premium to Last Round"
          helperText="Discount or premium of the share price compared to the company's latest funding round"
          defaultValue={deal.dealTerms?.premiumToLastRound || undefined}
          conditionsToShow={{
            matches: [
              {
                field: 'transactionType',
                condition: '===',
                value: 'SECONDARY',
              },
            ],
          }}
          validators={{
            required: true,
          }}
        />
        <FormInput
          type="radio-group"
          fieldName="premiumToLastRoundType"
          label="Premium to Last Round Type"
          helperText="Type of Discount or Premium"
          defaultValue={deal.dealTerms?.premiumToLastRoundType || undefined}
          options={premiumToLastRoundTypeOptions}
          conditionsToShow={{
            matches: [
              {
                field: 'transactionType',
                condition: '===',
                value: 'SECONDARY',
              },
            ],
          }}
          validators={{
            required: true,
          }}
        />
        <FormInput
          type="currency"
          fieldName="valuationOrCap"
          label="Valuation"
          defaultValue={deal.dealTerms?.valuationOrCap || undefined}
          validators={{
            required: true,
            minValue: 1,
          }}
        />
        <FormInput
          type="radio-group"
          fieldName="valuationType"
          label="Valuation Type"
          defaultValue={deal.dealTerms?.valuationType}
          options={valuationTypeOptions}
          conditionsToShow={{
            matches: [
              {
                field: 'transactionType',
                condition: '===',
                value: 'PRIMARY',
              },
            ],
          }}
          validators={{
            required: true,
          }}
        />
        <FormInput
          fullWidth
          type="select"
          fieldName="financialInstrument"
          label="Financial Instrument"
          defaultValue={deal.dealTerms?.financialInstrument}
          options={financialInstrumentTypeOptions}
          validators={{
            required: true,
          }}
        />
        <FormInput
          fullWidth
          type="select"
          fieldName="shareClass"
          label="Share Class"
          defaultValue={deal.dealTerms?.shareClass}
          options={shareClassTypeOptions}
          validators={{
            required: true,
          }}
        />
        <FormInput
          type="currency"
          fieldName="allocation"
          label="Your Allocation"
          defaultValue={deal.allocation || undefined}
          helperText="₹50L minimum suggested. You can change this anytime prior to closing."
          validators={{
            required: true,
          }}
        />
        <FormInput
          type="radio-group"
          fieldName="prorataRights"
          label="Pro-rata Rights"
          defaultValue={deal.dealTerms?.prorataRights}
          helperText="Will the SPV receive formal legal pro-rata rights?"
          options={prorataRightsTypeOptions}
          validators={{
            required: true,
          }}
        />
        <FormInput
          fullWidth
          type="textarea"
          fieldName="otherTerms"
          label="Other Terms"
          defaultValue={deal.dealTerms?.otherTerms}
          helperText="Non-standard liquidation preferences, interest rates, redemption rights, etc."
          validators={{
            required: true,
          }}
        />
        <FormInput
          type="date"
          fieldName="closingDate"
          label="Target Closing Date"
          defaultValue={deal.closingDate}
          helperText="Date we stop accepting new commitments. You can adjust this as needed."
          minDate={dayjs()}
          validators={{
            required: true,
          }}
        />
        <FormInput
          type="currency"
          fieldName="minimumInvestment"
          label="LP Minimum Investment"
          defaultValue={deal.dealTerms?.minimumInvestment || undefined}
          helperText="Default minimum LPs are required to invest. Modify later for individual LPs with custom invite links."
          validators={{
            required: true,
          }}
        />
        <FormInput
          type="text"
          fieldName="schemeName"
          label="Scheme name"
          defaultValue={data.deal.schemeName}
          helperText="Scheme name of the deal"
        />
      </FormPanelWithReadMode>
    </>
  );
};

export default BasicInformation;
