import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { ConvertibleAssetStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import FilterPanel from 'components/FilterPanel';

import statusEnumToReadable from 'utils/enums/status-enum-to-readable';

const CONVERTIBLE_ASSETS_APPROVAL_QUERY = gql(`
  query fetchConvertibleAssetsForApproval(
    $cursor: ID
    $limit: Int
    $filters: ConvertibleAssetsFilterType
  ) {
    convertibleAssets(cursor: $cursor, limit: $limit, filters: $filters) {
      nodes {
        id
        value
        status
        createdAt
        deal {
          id
          schemeName
          companyInformation {
            id
            company {
              id
              name
            }
          }
        }
        hasIssueWithValues
      }
      statistics {
        convertibleAssetStatisticsBreakdownByConvertibleAssetStatus {
          status
          numberOfConvertibleAssets
        }
      }
      pageInfo {
        hasNextPage
        cursor
      }
    }
  }
`);

const ConvertibleAssetsPage: FC = () => {
  const { loading, error, data, refetch, fetchMore, networkStatus, variables } = useQuery(
    CONVERTIBLE_ASSETS_APPROVAL_QUERY,
    {
      variables: {
        limit: 50,
        filters: {
          status: ConvertibleAssetStatusEnumType.UnderReview,
        },
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  if (loading && !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const convertibleAssets = data.convertibleAssets.nodes;
  const pageInfo = data.convertibleAssets.pageInfo;
  const statistics = data.convertibleAssets.statistics;

  const filters = [
    {
      type: 'status',
      label: 'Status',
      options: [
        {
          label: 'All',
          value: undefined,
        },
        ...statistics.convertibleAssetStatisticsBreakdownByConvertibleAssetStatus.map(
          ({ status, numberOfConvertibleAssets }) => ({
            label: `${statusEnumToReadable(status).label} (${numberOfConvertibleAssets})`,
            value: status,
          })
        ),
      ],
      readable: statusEnumToReadable,
    },
  ];

  return (
    <>
      <FilterPanel activeFilters={variables?.filters} filters={filters} refetch={refetch} />
      <DataTable
        data={convertibleAssets}
        onClick={convertibleAsset =>
          window.open(
            `/companies/${convertibleAsset.deal.companyInformation.company.id}/assets`,
            '_blank'
          )
        }
        columns={[
          {
            label: 'Deal',
            fieldName: 'deal.schemeName',
          },
          {
            label: 'Status',
            fieldName: 'status',
            type: 'STATUS',
          },
          {
            label: 'Value',
            fieldName: 'value',
            type: 'CURRENCY',
          },
          {
            label: 'Has Issue With Values',
            fieldName: 'hasIssueWithValues',
            type: 'BOOLEAN',
          },
          {
            label: 'Created At',
            fieldName: 'createdAt',
            type: 'DATE',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        filterLoading={networkStatus === NetworkStatus.setVariables}
        paginationLoading={loading}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
    </>
  );
};

export default ConvertibleAssetsPage;
