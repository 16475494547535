import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { PostStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_POST_MUTATION = gql(`
  mutation UpdatePostStatus($id: ID!, $status: PostStatusEnumType!) {
    updatePostStatus(id: $id, status: $status) {
      id
      status
      updatedAt
    }
  }
`);

const UpdatePostStatus: FC<{
  id: string;
  status: PostStatusEnumType;
  canUpdatePostStatus: boolean;
  isBeingEdited?: (boolean) => void;
}> = ({ id, status, canUpdatePostStatus, isBeingEdited }) => {
  const [updatePost, { loading, error }] = useMutation(UPDATE_POST_MUTATION);

  const options = [
    { label: 'Draft', value: 'DRAFT' },
    { label: 'Under Review', value: 'UNDER_REVIEW' },
    { label: 'Published', value: 'PUBLISHED' },
    { label: 'Deleted', value: 'DELETED' },
  ];

  const optionsToShow = options.filter(option => {
    if (status === PostStatusEnumType.UnderReview)
      return option.value !== PostStatusEnumType.Published;
    return option;
  });

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      disableEdit={!canUpdatePostStatus}
      isInReadOnlyMode={readOnlyMode => isBeingEdited && isBeingEdited(!readOnlyMode)}
      onSubmit={data => {
        updatePost({
          variables: {
            id,
            status: data.status,
          },
        });
      }}
    >
      <FormInput
        type="select"
        fieldName="status"
        label="Status"
        defaultValue={status}
        options={optionsToShow}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdatePostStatus;
