import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { PostStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import ConfirmButton from 'primitives/ConfirmButton';

const REMOVE_POST_MUTATION = gql(`
  mutation UpdatePostStatus($id: ID!, $status: PostStatusEnumType!) {
    updatePostStatus(id: $id, status: $status) {
      id
      status
      updatedAt
    }
  }
`);

const DeletePostButton: FC<{ id: string }> = ({ id }) => {
  const navigate = useNavigate();

  const [removePost, { loading, error }] = useMutation(REMOVE_POST_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        fields: {
          posts(existingPostsRef, { readField }) {
            const updatedPostsRef = { ...existingPostsRef };
            updatedPostsRef.nodes = existingPostsRef.nodes.filter(
              PostRef => id !== readField('id', PostRef)
            );
            return updatedPostsRef;
          },
        },
      });
    },
    onCompleted() {
      navigate('/approvals/posts');
    },
  });

  return (
    <ConfirmButton
      onConfirm={() => removePost({ variables: { id, status: PostStatusEnumType.Deleted } })}
      loading={loading}
      error={error}
      variant="secondary"
      description="This will delete all data concerning this post."
    >
      Remove
    </ConfirmButton>
  );
};

export default DeletePostButton;
