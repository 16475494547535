import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

const DEAL_EXITS_QUERY = gql(`
  query fetchDealExits($cursor: ID, $limit: Int) {
    dealExits(cursor: $cursor, limit: $limit) {
      nodes {
        id
        type
        percentageOfShares
        pricePerShare
        status
        deal {
          id
          schemeName
          companyInformation {
            id
            company {
              id
              name
            }
          }
        }
        exitedAt
        distributionExpenses
        multipleOnInvestmentOverride
        totalSaleProceeds
      }
      pageInfo {
        hasNextPage
        cursor
      }
    }
  }
`);

const DealExitsPage: FC = () => {
  const navigate = useNavigate();

  const { loading, error, data, refetch, fetchMore, networkStatus } = useQuery(DEAL_EXITS_QUERY, {
    variables: {
      limit: 50,
    },
    notifyOnNetworkStatusChange: true,
  });

  const renderContent = () => {
    if (loading && !data) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const dealExits = data.dealExits.nodes;
    const pageInfo = data.dealExits.pageInfo;

    return (
      <>
        <DataTable
          data={dealExits}
          onClick={dealExit => navigate(`/deal-exits/${dealExit.id}`)}
          columns={[
            {
              label: 'Scheme',
              fieldName: 'deal.schemeName',
            },
            {
              label: 'Company',
              fieldName: 'deal.companyInformation.company.name',
            },
            {
              label: 'Type',
              fieldName: 'type',
              type: 'DEAL_EXIT_TYPE',
            },
            {
              label: 'Percentage Of Shares',
              fieldName: 'percentageOfShares',
              type: 'PERCENTAGE',
            },
            {
              label: 'Price Per Share',
              fieldName: 'pricePerShare',
              type: 'CURRENCY',
            },
            {
              label: 'Status',
              fieldName: 'status',
              type: 'STATUS',
            },
            {
              label: 'Exited At',
              fieldName: 'exitedAt',
              type: 'DATE',
            },
            {
              label: 'Distribution Expenses',
              fieldName: 'distributionExpenses',
              type: 'NUMBER',
            },
            {
              label: 'MOI Override',
              fieldName: 'multipleOnInvestmentOverride',
              type: 'NUMBER',
            },
            {
              label: 'Total Sale Proceeds',
              fieldName: 'totalSaleProceeds',
              type: 'CURRENCY',
            },
          ]}
          hasNextPage={pageInfo.hasNextPage}
          filterLoading={networkStatus === NetworkStatus.setVariables}
          paginationLoading={loading}
          onLoadMore={() =>
            fetchMore({
              variables: {
                cursor: pageInfo.cursor,
              },
            })
          }
        />
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation title="Deal Exits" subTitle="View and manage all the deal exits" />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default DealExitsPage;
