import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { DealStatusEnumType } from '__generated__/graphql';
import classNames from 'classnames';
import { FC, useState } from 'react';

import Button from 'primitives/Button';
import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import { formatNumber } from 'utils/format-helper';

import CreateDealCarryRecipientDialogButton from './CreateCarryRecipientDialogButton';
import RemoveCarryRecipientDialogButton from './RemoveCarryRecipientDialogButton';
import UpdateCarryRecipientDialog from './UpdateCarryRecipientDialog';

const CARRY_RECIPIENTS_QUERY = gql(`
  query CarryRecipients($dealId: ID!, $syndicateId: ID!) {
    deal(id: $dealId) {
      id
      status
      totalLeadCarryPercentage
      carryRecipients {
        id
        name
        percentage
        isAngelListIndia
        investmentEntity {
          id
          name
          user {
            id
            name
          }
        }
      }
    }
    syndicate(id: $syndicateId) {
      id
      syndicateLeads {
        id
        user {
          id
          name
          investmentEntities {
            id
            name
          }
        }
      }
    }
  }
`);

const VERIFY_DEAL_CARRY_DISTIBUTION_MUTATION = gql(`
  mutation VerifyDealCarryDistribution($dealId: ID!) {
    verifyDealCarryDistribution(dealId: $dealId) {
      id
      isCarryDistributionVerified
    }
  }
`);

const CarryRecipients: FC<{ syndicateId: string; dealId: string }> = ({ syndicateId, dealId }) => {
  const [carryRecipientOnDisplay, setCarryRecipientOnDisplay] = useState<string | null>(null);

  const [verifyDealCarryDistribution, { loading: verifyLoading, error: verifyError }] = useMutation(
    VERIFY_DEAL_CARRY_DISTIBUTION_MUTATION,
    {
      variables: {
        dealId,
      },
    }
  );

  const { loading, error, data, refetch } = useQuery(CARRY_RECIPIENTS_QUERY, {
    variables: {
      dealId,
      syndicateId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data || !dealId) return <ErrorMessage error={error} refetch={refetch} />;

  const carryRecipients = data.deal.carryRecipients;
  const syndicateLeads = data.syndicate.syndicateLeads;

  function fetchAvailableLeadsForCarryDistribution() {
    const entitiesThatHaveBeenAssignedCarry = carryRecipients.map(
      carryRecipient => carryRecipient.investmentEntity?.id
    );

    return syndicateLeads.filter(syndicateLead => {
      if (!syndicateLead.user.investmentEntities) return false;
      return syndicateLead.user.investmentEntities.every(
        investmentEntity => !entitiesThatHaveBeenAssignedCarry.includes(investmentEntity.id)
      );
    });
  }

  function disableEdit() {
    if (!data?.deal.status) return false;

    return [
      DealStatusEnumType.Cancelled,
      DealStatusEnumType.Closed,
      DealStatusEnumType.Deleted,
      DealStatusEnumType.Exited,
      DealStatusEnumType.Wired,
    ].includes(data.deal.status);
  }

  return (
    <div className="border border-gray-200 p-4 rounded-lg">
      <div className="mb-4 rounded-lg grid grid-cols-1 gap-2">
        <div className="mb-4">
          <h3 className="text-base font-semibold">Carry Recipients</h3>
          {carryRecipients.length <= 1 && (
            <p className="text-md text-gray-500">
              Specify who will receive the carry and how much they will receive
            </p>
          )}
        </div>
        {carryRecipients.map(carryRecipient => (
          <div key={carryRecipient.id}>
            <div className={classNames('bg-white ring-1 ring-gray-200 rounded-lg px-4 py-2')}>
              <div className="flex items-start">
                <div className="flex-1">
                  <h3 className="text-md font-medium">
                    {carryRecipient.investmentEntity?.user.name || carryRecipient.name}
                  </h3>
                  <p className="text-sm text-gray-500">
                    Investment Entity:{' '}
                    <span className="text-gray-600 font-semibold">
                      {carryRecipient.investmentEntity?.name || 'Unspecified'}
                    </span>
                  </p>
                </div>
                <div className="flex flex-col justify-start">
                  <p className="text-md text-indigo-500">
                    {formatNumber(carryRecipient.percentage)} Carry Points
                  </p>
                </div>
              </div>
              {!disableEdit() && (
                <>
                  <div className="mt-4 flex gap-x-2 border-t pt-1">
                    <Button
                      variant="text"
                      className="!text-gray-700 hover:!text-gray-500"
                      onClick={() => setCarryRecipientOnDisplay(carryRecipient.id)}
                    >
                      Edit
                    </Button>
                    <RemoveCarryRecipientDialogButton id={carryRecipient.id} />
                  </div>
                  {carryRecipientOnDisplay === carryRecipient.id && (
                    <UpdateCarryRecipientDialog
                      key={carryRecipient.id}
                      id={carryRecipient.id}
                      percentage={carryRecipient.percentage}
                      totalLeadCarryPercentage={data.deal.totalLeadCarryPercentage}
                      investmentEntityId={carryRecipient.investmentEntity?.id}
                      syndicateLeads={syndicateLeads}
                      onClose={() => setCarryRecipientOnDisplay(null)}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        ))}
        {!disableEdit() && (
          <div>
            <CreateDealCarryRecipientDialogButton
              dealId={dealId}
              syndicateLeads={fetchAvailableLeadsForCarryDistribution()}
            />
          </div>
        )}
      </div>
      {!disableEdit() && (
        <>
          {verifyError && (
            <ErrorMessage type="alert" title="Carry distribution error" error={verifyError} />
          )}
          <div className="flex mt-4">
            <Button
              className="mr-2"
              loading={verifyLoading}
              onClick={() => verifyDealCarryDistribution()}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default CarryRecipients;
