import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';
import TextField from 'primitives/TextField';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import FilterPanel from 'components/FilterPanel';

import debounce from 'utils/debounce';
import investmentEntityTypeEnumToReadable from 'utils/enums/investment-entity-type-to-readable';
import statusEnumToReadable from 'utils/enums/status-enum-to-readable';

const INVESTMENT_ENTITIES_QUERY = gql(`
  query fetchInvestmentEntities($cursor: ID, $limit: Int, $filters: InvestmentEntitiesFilterType) {
    investmentEntities(cursor: $cursor, limit: $limit, filters: $filters) {
      nodes {
        id
        name
        user {
          id
          name
        }
        status
        type
        createdAt
        statistics {
          totalCommittedAmount
          totalInvestedAmount
        }
      }
      pageInfo {
        hasNextPage
        cursor
      }
      statistics {
        investmentEntityBreakdownByInvestmentEntityStatus {
          status
          numberOfInvestmentEntities
        }
        investmentEntityBreakdownByInvestmentEntityType {
          type
          numberOfInvestmentEntities
        }
      }
    }
  }
`);

const InvestmentEntitiesPage: FC = () => {
  const navigate = useNavigate();
  const { loading, error, data, refetch, fetchMore, variables, networkStatus } = useQuery(
    INVESTMENT_ENTITIES_QUERY,
    {
      variables: {
        limit: 50,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const renderContent = () => {
    if (loading && !data) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const investmentEntities = data.investmentEntities.nodes;
    const pageInfo = data.investmentEntities.pageInfo;
    const statistics = data.investmentEntities.statistics;

    const filters = [
      {
        type: 'status',
        label: 'Status',
        options: [
          {
            label: 'All',
            value: undefined,
          },
          ...statistics.investmentEntityBreakdownByInvestmentEntityStatus.map(
            ({ status, numberOfInvestmentEntities }) => ({
              label: `${statusEnumToReadable(status).label} (${numberOfInvestmentEntities})`,
              value: status,
            })
          ),
        ],
        readable: statusEnumToReadable,
      },
      {
        type: 'type',
        label: 'Type',
        options: [
          {
            label: 'All',
            value: undefined,
          },
          ...statistics.investmentEntityBreakdownByInvestmentEntityType.map(
            ({ type, numberOfInvestmentEntities }) => ({
              label: `${
                investmentEntityTypeEnumToReadable(type).label
              } (${numberOfInvestmentEntities})`,
              value: type,
            })
          ),
        ],
        readable: investmentEntityTypeEnumToReadable,
      },
    ];

    return (
      <>
        <FilterPanel activeFilters={variables?.filters} filters={filters} refetch={refetch} />
        <TextField
          placeholder="Search entities"
          onChange={debounce(e => {
            refetch({
              filters: {
                ...variables?.filters,
                text: e.target.value,
              },
            });
          }, 500)}
        />
        <DataTable
          data={investmentEntities}
          onClick={investmentEntity => navigate(`/investment-entities/${investmentEntity.id}`)}
          columns={[
            {
              label: 'Name',
              fieldName: 'name',
            },
            {
              label: 'User',
              fieldName: 'user.name',
            },
            {
              label: 'Status',
              fieldName: 'status',
              type: 'STATUS',
            },
            {
              label: 'Type',
              fieldName: 'type',
              type: 'INVESTMENT_ENTITY_TYPE',
            },
            {
              label: 'Created At',
              fieldName: 'createdAt',
              type: 'DATE',
            },
            {
              label: 'Total Amount Committed',
              fieldName: 'statistics.totalCommittedAmount',
              type: 'CURRENCY',
            },
            {
              label: 'Total Amount Invested',
              fieldName: 'statistics.totalInvestedAmount',
              type: 'CURRENCY',
            },
          ]}
          hasNextPage={pageInfo.hasNextPage}
          filterLoading={networkStatus === NetworkStatus.setVariables}
          paginationLoading={loading}
          onLoadMore={() =>
            fetchMore({
              variables: {
                cursor: pageInfo.cursor,
              },
            })
          }
        />
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Investment Entities"
        subTitle="View and manage all the investment entities"
      />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default InvestmentEntitiesPage;
