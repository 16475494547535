import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC, useState } from 'react';

import { formatCurrency } from 'utils/format-helper';

import ShareValuationChangeSlideOver from './ShareValuationChangeSlideOver';

const ShareValuationChangeCard: FC<{
  id: string;
  pricePerShare: number;
  effectiveFrom: string;
  wasConvertedFromUSD: boolean;
  createdAt: string;
  shareClasses: {
    id: string;
    name: string;
  }[];
}> = ({ id, pricePerShare, effectiveFrom, wasConvertedFromUSD, createdAt, shareClasses }) => {
  const [showSlideOver, toggleSlideOver] = useState(false);

  function lineItem(label: string, value: string, color: string = 'text-gray-600') {
    return (
      <div className="gap-x-2 flex">
        <span className="text-xs leading-5 text-gray-500">{label}:</span>
        <span className={classNames('text-xs font-semibold leading-5', color)}>{value}</span>
      </div>
    );
  }

  return (
    <>
      <ShareValuationChangeSlideOver
        open={showSlideOver}
        shareValuationChangeId={id}
        onClose={() => toggleSlideOver(false)}
      />
      <div
        className="p-2 ring-1 ring-red-50 sm:rounded-xl hover:ring-2 hover:shadow-sm hover:cursor-pointer max-w-md"
        onClick={() => toggleSlideOver(true)}
      >
        <div className="">
          <p className="font-semibold text-sm leading-6 text-red-500">
            Share price changed to {formatCurrency(pricePerShare)}
          </p>
          <div className="grid grid-cols-1 gap-x-4 mt-1">
            {lineItem('Affects', shareClasses.map(sc => sc.name).join(', '))}
          </div>
          <div className="grid grid-cols-2 gap-x-4 mt-1">
            {lineItem('Effective From', dayjs(effectiveFrom).format('DD MMMM YYYY'))}
            {wasConvertedFromUSD && lineItem('Was Converted From USD', 'Yes', 'text-green-500')}
            {lineItem('Created On', dayjs(createdAt).format('DD MMMM YYYY'))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareValuationChangeCard;
