import { useQuery } from '@apollo/client';
import { BuildingOffice2Icon, EyeIcon } from '@heroicons/react/24/outline';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import DeletePostButton from './DeletePostButton';
import PublishPostButton from './PublishPostButton';
import UpdatePost from './UpdatePost';
import UpdatePostStatus from './UpdatePostStatus';

const POST_QUERY = gql(`
  query getPost($id: ID!, $cursor: ID, $limit: Int) {
    post(id: $id) {
      id
      title
      message
      status
      createdAt
      updatedAt
      permissions {
        canUpdatePostStatus
      }
      deal {
        id
        status
      }
      syndicate {
        id
      }
      targetClosingStatuses
      targetClosings(cursor: $cursor, limit: $limit) {
        nodes {
          id
          amount
          status
          investmentEntity {
            id
            name
            user {
              id
              name
            }
          }
          fundingSource
        }
        pageInfo {
          hasNextPage
          cursor
          totalCount
        }
      }
    }
  }
`);

const PostPage: FC = () => {
  const navigate = useNavigate();
  const { postId } = useParams<{ postId: string }>() as { postId: string };
  const [showActionButtons, setShowActionButtons] = useState(true);

  const { loading, error, data, refetch, fetchMore } = useQuery(POST_QUERY, {
    variables: {
      id: postId,
    },
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const post = data.post;
    const closings = post.targetClosings?.nodes;
    const pageInfo = post.targetClosings?.pageInfo;

    return (
      <>
        <SidebarLayoutNavigation
          title="Post"
          subTitle="Manage Post"
          onBackButtonClick={() => navigate(-1)}
          actionButtons={[
            <NavLink
              type="button"
              className="relative inline-flex items-center rounded-l-md bg-white px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 aria-disabled:cursor-not-allowed"
              title="Go to Deal Page"
              to={`/deals/${post.deal?.id}`}
              target="_blank"
              aria-disabled={!post.deal}
              onClick={e => {
                if (!post.deal) e.preventDefault();
              }}
            >
              {post.deal ? (
                <>
                  <span className="mr-1 text-sm">View Deal</span>
                  <BuildingOffice2Icon className="h-5 w-5" aria-hidden="true" />
                </>
              ) : (
                <span className="mr-1 text-sm text-gray-500">No deal set</span>
              )}
            </NavLink>,
            <NavLink
              type="button"
              className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              title="Go to Syndicate Page"
              target="_blank"
              to={`/syndicates/${post.syndicate.id}`}
            >
              <span className="mr-1 text-sm">View Syndicate</span>
              <EyeIcon className="h-5 w-5" aria-hidden="true" />
            </NavLink>,
          ]}
        />
        <SidebarLayoutContent>
          <UpdatePost
            id={post.id}
            title={post.title}
            message={post.message}
            createdAt={post.createdAt}
            deal={post.deal}
            targetClosingStatuses={post.targetClosingStatuses}
            isBeingEdited={isBeingEdited => setShowActionButtons(isBeingEdited)}
          />
          <UpdatePostStatus
            id={post.id}
            status={post.status}
            canUpdatePostStatus={post.permissions.canUpdatePostStatus}
            isBeingEdited={isBeingEdited => setShowActionButtons(isBeingEdited)}
          />
          {closings && pageInfo ? (
            <div className="mt-8">
              <h3 className="text-sm mb-2">
                Holdings targeted by this post (Total: {pageInfo.totalCount})
              </h3>
              <DataTable
                data={closings}
                emptyListTitle="No closings targeted"
                columns={[
                  {
                    label: 'Investor',
                    fieldName: 'investmentEntity.user.name',
                  },
                  {
                    label: 'Entity',
                    fieldName: 'investmentEntity.name',
                  },
                  {
                    label: 'Status',
                    fieldName: 'status',
                    type: 'STATUS',
                  },
                  {
                    label: 'Funding Source',
                    fieldName: 'fundingSource',
                    type: 'FUNDING_SOURCE',
                  },
                  {
                    label: 'Amount',
                    fieldName: 'amount',
                    type: 'CURRENCY',
                  },
                ]}
                hasNextPage={pageInfo.hasNextPage}
                onLoadMore={() =>
                  fetchMore({
                    variables: {
                      cursor: pageInfo.cursor,
                    },
                  })
                }
              />
            </div>
          ) : null}
          {showActionButtons ? (
            <div className="grid grid-flow-col gap-4 mt-8">
              <DeletePostButton id={post.id} />
              <PublishPostButton
                id={post.id}
                canPublishPost={post.permissions.canUpdatePostStatus}
                totalClosingsTargeted={pageInfo?.totalCount}
              />
            </div>
          ) : null}
        </SidebarLayoutContent>
      </>
    );
  };

  return <SidebarLayout>{renderContent()}</SidebarLayout>;
};

export default PostPage;
