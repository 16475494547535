import { DealExitTypeEnumType } from '__generated__/graphql';

const dealExitTypeToReadable = (
  type: DealExitTypeEnumType
): {
  label: string;
  color: string;
} => {
  switch (type) {
    case DealExitTypeEnumType.Acquisition:
      return {
        label: 'Acquisition',
        color: 'green',
      };
    case DealExitTypeEnumType.Fundraise:
      return {
        label: 'Fundraise',
        color: 'yellow',
      };
    case DealExitTypeEnumType.Ipo:
      return {
        label: 'IPO',
        color: 'blue',
      };
    case DealExitTypeEnumType.ShutDown:
      return {
        label: 'Shutdown',
        color: 'red',
      };
    case DealExitTypeEnumType.SharesBuyback:
      return {
        label: 'Shares Buyback',
        color: 'purple',
      };
    case DealExitTypeEnumType.SecondarySale:
      return {
        label: 'Secondary Sale',
        color: 'orange',
      };
    case DealExitTypeEnumType.Other:
      return {
        label: 'Other',
        color: 'gray',
      };
    default:
      return {
        label: 'Unknown',
        color: 'gray',
      };
  }
};

export default dealExitTypeToReadable;
