import { useMutation } from '@apollo/client';
import { DialogActions } from '@mui/material';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';

import { FormInput, FormPanel } from 'components/FormPanel';

import SearchInvestmentEntities from './SearchInvestmentEntities';

const CREATE_FUND_CLOSING_MUTATION = gql(`
  mutation CreateFundClosing(
    $amount: Float!
    $fundingSource: FundingSourceEnumType!
    $investmentEntityId: ID!
    $fundId: ID!
  ) {
    createFundClosing(
      amount: $amount
      fundingSource: $fundingSource
      investmentEntityId: $investmentEntityId
      fundId: $fundId
    ) {
      id
      amount
      fundingSource
      investmentEntity {
        id
        name
      }
      status
      createdAt
      updatedAt
    }
  }
`);

const CreateFundClosingDialogButton: FC<{ fundId: string }> = ({ fundId }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showDialog, toggleDialog] = useState(false);
  const [investmentEntityId, setInvestmentEntityId] = useState<string | null>();

  const [createFundClosing, { loading, error }] = useMutation(CREATE_FUND_CLOSING_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: cache.identify({
          id: fundId,
          __typename: 'FundType',
        }),
        fields: {
          fundClosings(existingClosingsRef) {
            const newClosingNodeRef = cache.writeFragment({
              data: data.createFundClosing,
              fragment: gql(`
                fragment NewFundClosing on FundClosingType {
                  id
                  amount
                  fundingSource
                  investmentEntity {
                    id
                    name
                  }
                  status
                  createdAt
                  updatedAt
                }
              `),
            });
            const updatedClosingsRef = { ...existingClosingsRef };
            updatedClosingsRef.nodes = [newClosingNodeRef, ...updatedClosingsRef.nodes];
            return updatedClosingsRef;
          },
        },
      });
    },
  });

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Add an investor</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Add a new investor to the fund</DialogTitle>
          <DialogSubTitle>Fill in the details below to create a new fund commitment</DialogSubTitle>
          <SearchInvestmentEntities onSelect={ieId => setInvestmentEntityId(ieId)} />
          {investmentEntityId && (
            <FormPanel
              loading={loading}
              error={error}
              onCancel={() => toggleDialog(false)}
              onSubmit={data => {
                createFundClosing({
                  variables: {
                    amount: data.amount,
                    fundingSource: data.fundingSource,
                    investmentEntityId,
                    fundId,
                  },
                }).then(() => toggleDialog(false));
              }}
              buttonRef={buttonRef}
            >
              <FormInput
                fullWidth
                type="currency"
                fieldName="amount"
                label="Amount"
                defaultValue=""
              />
              <FormInput
                type="select"
                fieldName="fundingSource"
                label="Funding Source"
                defaultValue="INDIAN_ACCOUNT"
                options={[
                  { value: 'INDIAN_ACCOUNT', label: 'Indian Account' },
                  { value: 'FOREIGN_ACCOUNT', label: 'Foreign Account' },
                  {
                    value: 'NON_RESIDENT_ORDINARY_ACCOUNT',
                    label: 'Non Resident Ordinary Account',
                  },
                  {
                    value: 'NON_RESIDENT_EXTERNAL_ACCOUNT',
                    label: 'Non Resident External Account',
                  },
                ]}
              />
            </FormPanel>
          )}
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default CreateFundClosingDialogButton;
