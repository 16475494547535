import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

import SetupFeeEstimator from './SetupFeeEstimator';

const UPDATE_CLOSING_SETUP_FEE_MUTATION = gql(`
  mutation UpdateClosingSetupFee($id: ID!, $setupFee: Float!) {
    updateClosingSetupFee(id: $id, setupFee: $setupFee) {
      id
      setupFee
    }
  }
`);

const UpdateClosingSetupFee: FC<{
  id: string;
  amount: number;
  setupFee: number;
}> = ({ id, amount, setupFee }) => {
  const [updateClosingSetupFee, { loading, error }] = useMutation(
    UPDATE_CLOSING_SETUP_FEE_MUTATION,
    {
      refetchQueries: ['getClosingAudit', 'getClosingEmailAudit'],
    }
  );

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateClosingSetupFee({
          variables: {
            id,
            setupFee: data.setupFee,
          },
        });
      }}
    >
      <SetupFeeEstimator amount={amount} />
      <FormInput type="number" fieldName="setupFee" label="Setup Fee" defaultValue={setupFee} />
    </FormPanelWithReadMode>
  );
};

export default UpdateClosingSetupFee;
