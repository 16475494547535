import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import Button from 'primitives/Button';
import ConfirmButton from 'primitives/ConfirmButton';
import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import DistributionsTable from './DistributionsTable';
import RemoveDistributionsConfirmButton from './RemoveDistributionsConfirmButton';
import UpdateDealExit from './UpdateDealExit';

const DEAL_EXIT_QUERY = gql(`
  query getDealExit($id: ID!) {
    dealExit(id: $id) {
      id
      type
      percentageOfShares
      pricePerShare
      status
      exitedAt
      createdAt
      comments
      footnotes
      distributionExpenses
      multipleOnInvestmentOverride
      totalSaleProceeds
      deal {
        id
        schemeName
        companyInformation {
          id
          company {
            id
            name
          }
        }
      }
      distributions {
        nodes {
          id
          investmentEntity {
            id
            name
          }
          isNri
          isCarryRecipient
          investedAmount
          proceedsFromExit
          distributedAmount
          carryAmount
          taxDeduction
          status
          distributedAt
        }
      }
      fundDistributions {
        nodes {
          id
          investmentEntity {
            id
            name
          }
          isCarryRecipient
          investedAmount
          proceedsFromExit
          distributedAmount
          carryAmount
          taxDeduction
          status
          distributedAt
        }
      }
    }
  }
`);

const GENERATE_DISTRIBUTIONS_MUTATION = gql(`
  mutation generateDistributions($dealExitId: ID!) {
    generateDistributions(dealExitId: $dealExitId) {
      nodes {
        id
        investmentEntity {
          id
          name
        }
        isNri
        isCarryRecipient
        investedAmount
        proceedsFromExit
        distributedAmount
        carryAmount
        taxDeduction
      }
    }
  }
`);

const REMOVE_DEAL_EXIT_MUTATION = gql(`
  mutation removeDealExit($id: ID!) {
    removeDealExit(id: $id) {
      id
    }
  }
`);

const DealExitPage: FC = () => {
  const navigate = useNavigate();
  const { dealExitId } = useParams<{ dealExitId: string }>() as { dealExitId: string };

  const { loading, error, data, refetch } = useQuery(DEAL_EXIT_QUERY, {
    variables: {
      id: dealExitId,
    },
  });

  const [
    generateDistributions,
    { loading: generateDistributionsLoading, error: generateDistributionsError },
  ] = useMutation(GENERATE_DISTRIBUTIONS_MUTATION, {
    variables: {
      dealExitId,
    },
    refetchQueries: ['getDealExit'],
  });

  const [removeDealExit, { loading: removeDealExitLoading, error: removeDealExitError }] =
    useMutation(REMOVE_DEAL_EXIT_MUTATION, {
      variables: {
        id: dealExitId,
      },
      refetchQueries: ['fetchExitsByDeal', 'fetchDealExits', 'fetchDealExitsByCompany'],
      onCompleted() {
        navigate('/deal-exits');
      },
    });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const dealExit = data.dealExit;

    return (
      <>
        <SidebarLayoutNavigation
          title={dealExit.deal.schemeName || dealExit.deal.companyInformation.company.name}
          subTitle="Deal Exit"
          onBackButtonClick={() => navigate(-1)}
        />
        <SidebarLayoutContent>
          <UpdateDealExit dealExit={dealExit} deal={dealExit.deal} />

          <div className="space-y-4 mt-6">
            <div className="flex gap-4">
              {!data.dealExit.distributions.nodes?.length &&
              !data.dealExit.fundDistributions.nodes?.length ? (
                <Button
                  onClick={generateDistributions}
                  loading={generateDistributionsLoading}
                  variant="primary"
                >
                  Generate Distributions
                </Button>
              ) : (
                <>
                  <RemoveDistributionsConfirmButton dealExitId={dealExitId} />

                  <ConfirmButton
                    onConfirm={removeDealExit}
                    loading={removeDealExitLoading}
                    variant="secondary"
                    title="Are you sure you want to revert this deal exit?"
                    description="This action will remove this exit and associated data. It was also revert the status of the deal back to FINALISED and the associated share assets back to ACTIVE."
                  >
                    Remove Deal Exit
                  </ConfirmButton>
                </>
              )}
            </div>

            {generateDistributionsError && <ErrorMessage error={generateDistributionsError} />}
            {removeDealExitError && <ErrorMessage error={removeDealExitError} />}
          </div>

          <div className="space-y-8 mt-8">
            <div>
              <h3 className="text-lg font-medium mb-4">Investor Distributions</h3>
              <DistributionsTable
                distributions={data.dealExit.distributions.nodes?.filter(Boolean) || []}
                onUpdate={refetch}
              />
            </div>

            <div>
              <h3 className="text-lg font-medium mb-4">Fund Distributions</h3>
              <DistributionsTable
                distributions={data.dealExit.fundDistributions.nodes?.filter(Boolean) || []}
                onUpdate={refetch}
              />
            </div>
          </div>
        </SidebarLayoutContent>
      </>
    );
  };

  return <SidebarLayout>{renderContent()}</SidebarLayout>;
};

export default DealExitPage;
