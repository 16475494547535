import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const SYNDICATE_REDIS_SYNC = gql(`
  mutation AddSyndicatesToIndex {
    addSyndicatesToIndex
    {
      count
      status
    }
  }
`);

const SyndicateSyncDialogButton: FC<{}> = () => {
  const [initiateSyndicateRedisSync, { loading, error }] = useMutation(SYNDICATE_REDIS_SYNC);

  return (
    <ConfirmButton
      loading={loading}
      error={error}
      onConfirm={() => initiateSyndicateRedisSync()}
      description="This will add all syndicates to the redis search index."
    >
      Add Syndicates To Index
    </ConfirmButton>
  );
};

export default SyndicateSyncDialogButton;
