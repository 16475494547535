import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

const GENERATE_TERM_SHEET_MUTATION = gql(`
  mutation GenerateTermSheetForDeal($id: ID!) {
    generateTermSheet(id: $id)
  }
`);

const GenerateTermSheetDialogButton: FC<{ dealId: string }> = ({ dealId }) => {
  const [showDialog, toggleDialog] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [generateTermSheet, { loading }] = useMutation(GENERATE_TERM_SHEET_MUTATION);

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Generate Term Sheet</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Generate Term Sheet</DialogTitle>
          <p>Are you sure you want to generate a term sheet for this deal?</p>
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                generateTermSheet({
                  variables: {
                    id: dealId,
                  },
                }).then(response => {
                  if (response.data?.generateTermSheet) {
                    window.open(response.data.generateTermSheet, '_blank');
                  }
                  toggleDialog(false);
                });
              }}
              loading={loading}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default GenerateTermSheetDialogButton;
