import {
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Combobox as HeadlessCombobox,
} from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

const Combobox = ({
  options,
  onChange,
  value,
  error,
  label,
  placeholder,
  fullWidth = false,
  helperText,
  readOnly,
}: {
  options: { value: string; label: string; color?: string }[];
  onChange: (e: string) => void;
  value?: string;
  label?: string;
  error?: string;
  placeholder?: string;
  fullWidth?: boolean;
  helperText?: string;
  readOnly?: boolean;
}) => {
  const [selected, setSelected] = useState(options.find(o => o.value === value));

  useEffect(() => {
    setSelected(options.find(o => o.value === value));
  }, [value, options]);
  const [query, setQuery] = useState('');

  const filteredOptions =
    query === ''
      ? options
      : options.filter(option => {
          return option.label.toLowerCase().includes(query.toLowerCase());
        });

  const renderContent = () => {
    return (
      <HeadlessCombobox
        value={selected?.label}
        onChange={value => {
          const option = options.find(o => o.value === value);
          if (value) onChange(value);
          setSelected(option);
        }}
        onClose={() => setQuery('')}
      >
        <div className="relative">
          <ComboboxInput
            aria-label="Option"
            onChange={event => setQuery(event.target.value)}
            className={classNames(
              'w-full rounded-lg border-gray-300 bg-white/5 py-1.5 pr-8 pl-3 text-sm/6 text-black',
              'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25',
              { 'cursor-default': readOnly }
            )}
            placeholder={placeholder}
            disabled={readOnly}
          />
          {!readOnly ? (
            <ComboboxButton className="group absolute inset-y-0 right-0 px-2.5">
              <ChevronDownIcon className="size-4 fill-gray-50 group-data-[hover]:fill-white-500" />
            </ComboboxButton>
          ) : null}
        </div>
        {!readOnly ? (
          <ComboboxOptions
            anchor="bottom"
            className={classNames(
              'w-[var(--input-width)] rounded-xl border border-gray-300 bg-white p-1 [--anchor-gap:var(--spacing-1)] empty:invisible',
              'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
            )}
          >
            {filteredOptions.map((option, idx) => (
              <ComboboxOption
                key={option.label + idx}
                value={option.value}
                className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-gray-100"
              >
                <CheckIcon className="invisible size-4 fill-white group-data-[selected]:visible" />
                <div className="text-sm/6 text-black">{option.label}</div>
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        ) : null}
      </HeadlessCombobox>
    );
  };

  return (
    <div>
      {label && (
        <label htmlFor={label} className="block text-sm font-medium leading-6 text-gray-900 mb-1">
          {label}
        </label>
      )}
      {renderContent()}
      {helperText && !error && <p className="mt-2 text-sm text-gray-500">{helperText}</p>}
      {error && (
        <p className="mt-2 text-sm text-red-600" id="error">
          {error}
        </p>
      )}
    </div>
  );
};

export default Combobox;
