import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const PUBLISH_POST_MUTATION = gql(`
  mutation PublishPost($id: ID!) {
    publishPost(id: $id) {
      id
      status
    }
  }
`);

const PublishPostButton: FC<{
  id: string;
  canPublishPost: boolean;
  totalClosingsTargeted?: number;
}> = ({ id, canPublishPost, totalClosingsTargeted }) => {
  const [publishPost, { loading, error }] = useMutation(PUBLISH_POST_MUTATION);

  return (
    <ConfirmButton
      onConfirm={() => publishPost({ variables: { id } })}
      loading={loading}
      error={error}
      disabled={!canPublishPost}
      description={`Publishing this post will trigger an email to ${
        totalClosingsTargeted || 'all'
      } recipient(s). This cannot be undone.`}
    >
      Publish
    </ConfirmButton>
  );
};

export default PublishPostButton;
