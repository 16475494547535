import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import postIcon from 'media/icons/post.svg';

const Assets: FC = () => {
  const tabs = [
    {
      key: 'share-assets',
      label: `Share Assets`,
      description: 'Share Assets',
      icon: postIcon,
      route: `/assets`,
      exact: true,
    },
    {
      key: 'convertible-assets',
      label: `Convertible Assets`,
      description: 'Convertible Assets',
      icon: postIcon,
      route: `/assets/convertible-assets`,
    },
    {
      key: 'companies',
      label: `Companies`,
      description: 'Companies',
      icon: postIcon,
      route: `/assets/companies`,
    },
  ];

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation title="Assets" subTitle="The various assets we currently manage" />
      <SidebarLayoutContent secondaryTabs={tabs}>
        <Outlet />
      </SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default Assets;
