import { useQuery } from '@apollo/client';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import { gql } from '__generated__/gql';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC } from 'react';

import Badge from 'primitives/Badge';
import Button from 'primitives/Button';
import LoadingIndicator from 'primitives/LoadingIndicator';
import TextField from 'primitives/TextField';

import ErrorMessage from 'components/ErrorMessage';

import debounce from 'utils/debounce';
import statusEnumToReadable from 'utils/enums/status-enum-to-readable';
import { formatCurrency } from 'utils/format-helper';

const ASSETS_QUERY = gql(`
  query fetchAssetsByCompanies($cursor: ID, $limit: Int, $filters: CompaniesFilterType) {
    companies(cursor: $cursor, limit: $limit, filters: $filters) {
      nodes {
        id
        name
        legalName
        deals {
          id
          schemeName
          status
          finalisedAt
          multipleOnInvestmentOverride
          statistics {
            totalWiredAmount
            multiple
          }
          oldPlatformData {
            hasIssueWithForeignCurrency
          }
        }
      }
      pageInfo {
        hasNextPage
        cursor
      }
    }
  }
`);

const CompanyCard = ({ id, name, legalName, deals }) => {
  return (
    <div
      className="grid grid-cols-8 text-sm hover:bg-gray-50 cursor-pointer"
      onClick={() => window.open(`/companies/${id}`, '_blank')}
    >
      {deals.map((deal, idx) => {
        const multipleValue = Math.round(deal.statistics.multiple * 100) / 100;
        const { label, color } = statusEnumToReadable(deal.status);

        return (
          <>
            <span className="col-span-1 p-1 border-l border-r border-b border-gray-200 font-semibold">
              {idx === 0 ? name : ''}
            </span>
            <span className="col-span-1 p-1 border-r border-b border-gray-200">
              {idx === 0 ? legalName : ''}
            </span>
            <span className="col-span-1 p-1 border-r border-b border-gray-200 font-semibold">
              {deal.schemeName}
            </span>
            <span className="col-span-1 p-1 border-r border-b border-gray-200">
              <Badge color={color} label={label} />
            </span>
            <span className="col-span-1 p-1 border-r border-b border-gray-200">
              {dayjs(deal.finalisedAt).format('YYYY-MM-DD')}
            </span>
            <span className="col-span-1 p-1 border-r border-b border-gray-200">
              {formatCurrency(deal.statistics.totalWiredAmount)}
            </span>
            <span className="col-span-1 p-1 border-r border-b border-gray-200">
              {deal.statistics.multiple ? (
                <>
                  <div
                    className={classNames(
                      'inline-flex items-baseline rounded-md px-2.5 py-0.5 text-xs font-medium md:mt-2 lg:mt-0',
                      {
                        'bg-green-100 text-green-800': multipleValue > 1,
                        'bg-red-100 text-red-800': multipleValue < 1,
                        'bg-gray-100 text-gray-800': multipleValue === 1,
                      }
                    )}
                  >
                    {multipleValue > 1 && (
                      <ArrowUpIcon
                        className="-ml-1 mr-0.5 h-3.5 w-3.5 flex-shrink-0 self-center text-green-500"
                        aria-hidden="true"
                      />
                    )}
                    {multipleValue < 1 && (
                      <ArrowDownIcon
                        className="-ml-1 mr-0.5 h-3.5 w-3.5 flex-shrink-0 self-center text-red-500"
                        aria-hidden="true"
                      />
                    )}
                    {deal.statistics.multiple.toFixed(2)}x
                  </div>
                  {deal.multipleOnInvestmentOverride !== null &&
                  deal.multipleOnInvestmentOverride !== undefined ? (
                    <span className="text-gray-700 text-xs">
                      {' '}
                      ({deal.multipleOnInvestmentOverride}x - Override)
                    </span>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                <div className="inline-flex items-center">
                  <span className="text-gray-400">N/A</span>
                  {deal.multipleOnInvestmentOverride !== null &&
                  deal.multipleOnInvestmentOverride !== undefined ? (
                    <span className="text-gray-700 text-xs">
                      {' '}
                      ({deal.multipleOnInvestmentOverride}x - Override)
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              )}
            </span>
            <span className="col-span-1 p-1 border-r border-b border-gray-200">
              {deal.hasIssueWithForeignCurrency ? 'Yes' : ''}
            </span>
          </>
        );
      })}
    </div>
  );
};

const AssetsCompaniesPage: FC = () => {
  const { loading, error, data, refetch, fetchMore, variables } = useQuery(ASSETS_QUERY, {
    variables: {
      limit: 50,
    },
    notifyOnNetworkStatusChange: true,
  });

  if (loading && !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const companies = data.companies.nodes;

  return (
    <div className="relative">
      <div className="mb-4">
        <TextField
          placeholder="Search companies"
          onChange={debounce(e => {
            refetch({
              filters: {
                ...variables?.filters,
                text: e.target.value,
              },
            });
          }, 500)}
        />
      </div>

      <div className="grid grid-cols-8 text-sm font-bold text-indigo-600 sticky top-[4.6rem] z-50 bg-gray-100 border-t border-gray-200 rounded-t-lg">
        <span className="col-span-1 p-1 border-l border-r border-b border-gray-200 h-10 rounded-tl-lg">
          Company
        </span>
        <span className="col-span-1 p-1 border-r border-b border-gray-200">Legal Name</span>
        <span className="col-span-1 p-1 border-r border-b border-gray-200">Deal</span>
        <span className="col-span-1 p-1 border-r border-b border-gray-200">Status</span>
        <span className="col-span-1 p-1 border-r border-b border-gray-200">Finalised At</span>
        <span className="col-span-1 p-1 border-r border-b border-gray-200">Wired Amount</span>
        <span className="col-span-1 p-1 border-r border-b border-gray-200">Multiple</span>
        <span className="col-span-1 p-1 border-r border-b border-gray-200 rounded-tr-lg">
          USD Issue
        </span>
      </div>
      {companies.map((company, idx) => (
        <>
          <CompanyCard
            key={company.id}
            id={company.id}
            name={company.name}
            legalName={company.legalName}
            deals={company.deals}
          />
          <div className="grid grid-cols-8 text-sm">
            <span className="col-span-1 p-1 border-l border-r border-b border-gray-200 h-10 inline-flex items-center text-gray-500">
              <ArrowUpIcon className="w-3 h-3" /> {idx + 1}
            </span>
            <span className="col-span-1 p-1 border-r border-b border-gray-200" />
            <span className="col-span-1 p-1 border-r border-b border-gray-200" />
            <span className="col-span-1 p-1 border-r border-b border-gray-200" />
            <span className="col-span-1 p-1 border-r border-b border-gray-200" />
            <span className="col-span-1 p-1 border-r border-b border-gray-200" />
            <span className="col-span-1 p-1 border-r border-b border-gray-200" />
            <span className="col-span-1 p-1 border-r border-b border-gray-200" />
          </div>
        </>
      ))}
      {data.companies.pageInfo.hasNextPage && (
        <div className="flex justify-center mt-2">
          <Button
            loading={loading}
            onClick={() => fetchMore({ variables: { cursor: data.companies.pageInfo.cursor } })}
          >
            Load More
          </Button>
        </div>
      )}
    </div>
  );
};

export default AssetsCompaniesPage;
