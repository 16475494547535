import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC, useState } from 'react';

import Badge from 'primitives/Badge';

import statusEnumToReadable from 'utils/enums/status-enum-to-readable';
import { formatCurrency, formatNumber } from 'utils/format-helper';

import ShareClassSlideOver from './ShareClassSlideOver';
import WarningDialog from './WarningDialog';

type ShareAsset = {
  id: string;
  status: string;
  createdAt: string;
  numberOfShares: number;
  deal: {
    id: string;
    schemeName?: string | null;
    statistics: {
      totalWiredAmount: number;
      multiple?: number | null;
    };
  };
  sourceConvertibleAsset?: any;
};

const ShareAssetCard: FC<{
  asset: ShareAsset;
  multiple: number;
}> = ({ asset, multiple }) => {
  const [showMultipleMismatchDialog, toggleMultipleMismatchDialog] = useState(false);
  const [showSourceConvertibleAssetDialog, toggleSourceConvertibleAssetDialog] = useState(false);
  const { label: statusLabel, color: statusColor } = statusEnumToReadable(asset.status);

  return (
    <>
      <WarningDialog
        open={showMultipleMismatchDialog}
        onClose={() => toggleMultipleMismatchDialog(false)}
        title="Multiple Shown is Different"
        message={
          <p>
            <strong>Note: </strong>The multiple (
            <strong>{Math.round((asset.deal.statistics.multiple ?? 0) * 100) / 100}</strong>) shown
            to the investor might be different because the wired amount of the deal{' '}
            <strong>₹ {formatNumber(asset.deal.statistics.totalWiredAmount)}</strong> does not match
            the aggregate of{' '}
            <span className="font-mono">(numberOfShares * issuePricePerShare)</span>.
          </p>
        }
      />
      <WarningDialog
        open={showSourceConvertibleAssetDialog}
        onClose={() => toggleSourceConvertibleAssetDialog(false)}
        title="Convertible Asset"
        message={
          <p>
            <strong>Note: </strong>This share asset was converted from a convertible asset of deal:{' '}
            <strong>{asset.sourceConvertibleAsset?.deal.schemeName}</strong>
          </p>
        }
      />
      <li className="grid grid-cols-6 text-xs font-medium p-2">
        <span className="col-span-1 p-1 border-r border-gray-100">
          {asset.deal.schemeName || 'Unknown Scheme'}
        </span>
        <span className="col-span-1 p-1 border-r border-gray-100">Share Purchase</span>
        <span className="col-span-1 p-1 border-r border-gray-100">
          {dayjs(asset.createdAt).format('DD MMM YYYY')}
        </span>
        <span className="col-span-1 p-1 border-r border-gray-100">
          {formatNumber(asset.numberOfShares)}
        </span>
        <span className="col-span-1 p-1 border-r border-gray-100 inline-flex items-start gap-1.5">
          {formatNumber(asset.deal.statistics.multiple ?? 0)}x
          {multiple !== asset.deal.statistics.multiple && !asset.sourceConvertibleAsset && (
            <ExclamationTriangleIcon
              className="w-4 h-4 text-orange-500"
              onClick={e => {
                e.stopPropagation();
                toggleMultipleMismatchDialog(true);
              }}
            />
          )}
        </span>
        <span className="col-span-1 p-1 inline-flex items-start gap-1.5">
          <Badge label={statusLabel} color={statusColor} />
          {asset.sourceConvertibleAsset && (
            <ExclamationTriangleIcon
              className="w-4 h-4 mt-1 text-orange-500"
              onClick={e => {
                e.stopPropagation();
                toggleSourceConvertibleAssetDialog(true);
              }}
            />
          )}
        </span>
      </li>
    </>
  );
};

const ShareClassCard: FC<{
  id: string;
  name: string;
  issueDate: string;
  issuePricePerShare: number;
  latestPricePerShare: number;
  assets: ShareAsset[];
}> = ({ id, name, issueDate, issuePricePerShare, latestPricePerShare, assets }) => {
  const [showSlideOver, toggleSlideOver] = useState(false);

  const priceMovement = Math.round((latestPricePerShare - issuePricePerShare) * 100) / 100;

  function lineItem(label: string, value: string, color: string = 'text-gray-800') {
    return (
      <div className="my-0.5 grid grid-cols-2">
        <span className="text-sm leading-5 text-gray-600">{label}:</span>
        <span className={classNames('text-sm font-semibold leading-5', color)}>{value}</span>
      </div>
    );
  }

  return (
    <>
      <ShareClassSlideOver
        open={showSlideOver}
        shareClassId={id}
        onClose={() => toggleSlideOver(false)}
      />
      <div
        className="p-2 ring-1 ring-indigo-100 sm:rounded-md hover:shadow-sm hover:ring-2 hover:cursor-pointer"
        onClick={() => toggleSlideOver(true)}
      >
        <div className="grid grid-cols-4 gap-x-2">
          <div className="p-2 bg-gray-50 sm:rounded-md col-span-1">
            <Badge label={'Share Class'} color={'indigo'} />
            <p className="mt-1 font-semibold leading-6 text-gray-700">{name}</p>
            <div className="grid grid-cols-1 gap-x-4 mt-2">
              {lineItem('Issue Date', dayjs(issueDate).format('DD MMM YYYY'))}
              {lineItem('Issue PPS', formatCurrency(issuePricePerShare))}
              {lineItem(
                'Latest PPS',
                formatCurrency(latestPricePerShare),
                priceMovement > 0
                  ? 'text-green-500'
                  : priceMovement < 0
                  ? 'text-red-500'
                  : 'text-gray-800'
              )}
              {lineItem(
                'Multiple',
                `${formatNumber(latestPricePerShare / issuePricePerShare)}x`,
                priceMovement > 0
                  ? 'text-green-500'
                  : priceMovement < 0
                  ? 'text-red-500'
                  : 'text-gray-800'
              )}
            </div>
          </div>
          <div className="col-span-3">
            <ul className="divide-y divide-gray-100 overflow-hidden rounded-md ring-1 ring-gray-100">
              <li className="grid grid-cols-6 text-xs font-semibold p-2 text-gray-600 bg-gray-50">
                <span className="col-span-1 p-1 border-r border-gray-200">Scheme</span>
                <span className="col-span-1 p-1 border-r border-gray-200">Type</span>
                <span className="col-span-1 p-1 border-r border-gray-200">Created At</span>
                <span className="col-span-1 p-1 border-r border-gray-200">Number of Shares</span>
                <span className="col-span-1 p-1 border-r border-gray-200">Multiple</span>
                <span className="col-span-1 p-1">Status</span>
              </li>
              {assets.length === 0 && (
                <p className="p-4 text-sm text-center text-gray-500">
                  No deals or assets in this share class
                </p>
              )}

              {assets.map(asset => (
                <ShareAssetCard
                  key={asset.id}
                  asset={asset}
                  multiple={latestPricePerShare / issuePricePerShare}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareClassCard;
