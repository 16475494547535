import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import CompanySyncConfirmButton from './CompanySyncConfirmButton';
import PlatformPreferences from './PlatformPreferences';
import SyndicateSyncConfirmButton from './SyndicateSyncConfirmButton';

const PLATFORM_PREFERENCES_QUERY = gql(`
  query getPlatfromPreferences {
    platformPreferences {
      emailEnabled
      id
      whatsappEnabled
      pushNotificationsEnabled
      permissionsEnabled
      smsEnabled
      redisEnabled
    }
  }
`);

const ControlPanel: FC = () => {
  const { loading, error, data, refetch } = useQuery(PLATFORM_PREFERENCES_QUERY);

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const platformPreferences = data.platformPreferences;

    if (!platformPreferences) return <ErrorMessage error={error} refetch={refetch} />;
    return (
      <>
        <PlatformPreferences
          id={platformPreferences.id}
          whatsappEnabled={platformPreferences.whatsappEnabled}
          emailEnabled={platformPreferences.emailEnabled}
          smsEnabled={platformPreferences.smsEnabled}
          pushNotificationsEnabled={platformPreferences.pushNotificationsEnabled}
          redisEnabled={platformPreferences.redisEnabled}
          permissionsEnabled={platformPreferences.permissionsEnabled}
        />
        <div className="flex gap-2 mt-4">
          <CompanySyncConfirmButton />
          <SyndicateSyncConfirmButton />
        </div>
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Control Panel"
        subTitle="Manage your platform preferences and system operations"
      />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default ControlPanel;
