import {
  ConvertibleAssetStatusEnumType,
  ConvertibleAssetTypeEnumType,
} from '__generated__/graphql';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC, useState } from 'react';

import Badge from 'primitives/Badge';

import convertibleAssetTypeToReadable from 'utils/enums/convertible-asset-type-to-readable';
import statusEnumToReadable from 'utils/enums/status-enum-to-readable';
import { formatCurrency, formatCurrencyToShort, formatNumber } from 'utils/format-helper';

import ConvertibleClassSlideOver from './ConvertibleClassSlideOver';

type ConvertibleAsset = {
  id: string;
  type: ConvertibleAssetTypeEnumType;
  status: ConvertibleAssetStatusEnumType;
  createdAt: string;
  value: number;
  maturityDate?: string | null;
  convertedAt?: string | null;
  deal: {
    id: string;
    schemeName?: string | null;
    statistics: {
      totalWiredAmount: number;
      multiple?: number | null;
    };
  };
};

const ConvertibleAssetCard: FC<{
  asset: ConvertibleAsset;
  onClick: (id: string) => void;
}> = ({ asset }) => {
  const { label: statusLabel, color: statusColor } = statusEnumToReadable(asset.status);
  const { label: typeLabel, color: typeColor } = convertibleAssetTypeToReadable(asset.type);

  return (
    <li className="grid grid-cols-6 text-xs font-medium p-2">
      <span className="col-span-1 p-1 border-r border-gray-100">
        {asset.deal.schemeName || 'Unknown Scheme'}
      </span>
      <span className="col-span-1 p-1 border-r border-gray-100">
        <Badge label={typeLabel} color={typeColor} />
      </span>
      <span className="col-span-1 p-1 border-r border-gray-100">
        {dayjs(asset.createdAt).format('DD MMM YYYY')}
      </span>
      <span className="col-span-1 p-1 border-r border-gray-100">{formatCurrency(asset.value)}</span>
      <span className="col-span-1 p-1 border-r border-gray-100">
        {dayjs(asset.maturityDate).format('DD MMM YYYY')}
      </span>
      <span className="col-span-1 p-1 inline-flex items-start gap-1.5">
        <Badge label={statusLabel} color={statusColor} />
      </span>
    </li>
  );
};

const ConvertibleClassCard: FC<{
  id: string;
  name: string;
  valuationOrCap: number;
  latestValuationOrCap: number;
  discount: number;
  issueDate: string;
  assets: ConvertibleAsset[];
}> = ({ id, name, valuationOrCap, latestValuationOrCap, discount, issueDate, assets }) => {
  const [showSlideOver, toggleSlideOver] = useState(false);

  const valuationMovement = Math.round((latestValuationOrCap - valuationOrCap) * 100) / 100;

  function lineItem(label: string, value: string, color: string = 'text-gray-800') {
    return (
      <div className="my-0.5 grid grid-cols-2">
        <span className="text-sm leading-5 text-gray-600">{label}:</span>
        <span className={classNames('text-sm font-semibold leading-5', color)}>{value}</span>
      </div>
    );
  }

  return (
    <>
      <ConvertibleClassSlideOver
        open={showSlideOver}
        convertibleClassId={id}
        onClose={() => toggleSlideOver(false)}
      />
      <div
        className="p-2 ring-1 ring-indigo-100 sm:rounded-md hover:shadow-sm hover:ring-2 hover:cursor-pointer"
        onClick={() => toggleSlideOver(true)}
      >
        <div className="grid grid-cols-4 gap-x-2">
          <div className="p-2 bg-gray-50 sm:rounded-md col-span-1">
            <Badge label={'Convertible Class'} color={'amber'} />
            <p className="mt-1 font-semibold leading-6 text-gray-700">{name}</p>
            <div className="grid grid-cols-1 gap-x-4 mt-2">
              {lineItem('Issue Date', dayjs(issueDate).format('DD MMM YYYY'))}
              {lineItem('Issue Val/Cap', formatCurrencyToShort(valuationOrCap))}
              {lineItem('Discount', `${formatNumber(discount * 100)} %`)}
              {lineItem(
                'Latest Val/Cap',
                formatCurrencyToShort(latestValuationOrCap),
                valuationMovement > 0
                  ? 'text-green-500'
                  : valuationMovement < 0
                  ? 'text-red-500'
                  : 'text-gray-800'
              )}
              {lineItem(
                'Multiple',
                `${formatNumber(latestValuationOrCap / valuationOrCap)}x`,
                valuationMovement > 0
                  ? 'text-green-500'
                  : valuationMovement < 0
                  ? 'text-red-500'
                  : 'text-gray-800'
              )}
            </div>
          </div>
          <div className="col-span-3">
            <ul className="divide-y divide-gray-100 overflow-hidden rounded-md ring-1 ring-gray-100">
              <li className="grid grid-cols-6 text-xs font-semibold p-2 text-gray-600 bg-gray-50">
                <span className="col-span-1 p-1 border-r border-gray-200">Scheme</span>
                <span className="col-span-1 p-1 border-r border-gray-200">Type</span>
                <span className="col-span-1 p-1 border-r border-gray-200">Created At</span>
                <span className="col-span-1 p-1 border-r border-gray-200">Purchase Value</span>
                <span className="col-span-1 p-1 border-r border-gray-200">Maturity Date</span>
                <span className="col-span-1 p-1">Status</span>
              </li>
              {assets.length === 0 && (
                <p className="p-4 text-sm text-center text-gray-500">
                  No deals or assets in this share class
                </p>
              )}
              {assets.map(asset => (
                <ConvertibleAssetCard key={asset.id} asset={asset} onClick={() => {}} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConvertibleClassCard;
