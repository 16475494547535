import { FC } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

const WarningDialog: FC<{
  open: boolean;
  onClose: () => void;
  title: string;
  message: string | JSX.Element;
}> = ({ open, onClose, title, message }) => {
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogPanel>
          <DialogTitle>{title}</DialogTitle>
          {message}
          <DialogActions className="!grid-cols-1">
            <Button variant="secondary" onClick={onClose}>
              Close
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default WarningDialog;
