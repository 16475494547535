import { ApolloError } from '@apollo/client';
import { FC, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

import ErrorMessage from 'components/ErrorMessage';

// Description: A button that opens a confirmation dialog before executing the action

const ConfirmButton: FC<{
  children: string | JSX.Element;
  onConfirm: any;
  loading?: boolean;
  error?: ApolloError;
  title?: string;
  description?: string;
  confirmButtonLabel?: string;
  disabled?: boolean;
  variant?: 'primary' | 'secondary';
  className?: string;
}> = ({
  children,
  onConfirm,
  loading,
  error,
  title,
  description,
  confirmButtonLabel,
  disabled,
  variant = 'primary',
  className,
}) => {
  const [showConfirmDialog, toggleConfirmDialog] = useState(false);

  return (
    <>
      <Button
        className={className}
        variant={variant}
        onClick={() => toggleConfirmDialog(true)}
        disabled={disabled}
      >
        {children}
      </Button>
      <Dialog open={showConfirmDialog} onClose={() => toggleConfirmDialog(false)}>
        <DialogPanel>
          <DialogTitle>{title || 'Are you sure?'}</DialogTitle>
          <p className="mb-4">{description || 'Please confirm your action'}</p>
          {error && <ErrorMessage error={error} type="alert" />}
          <DialogActions>
            <Button variant="secondary" onClick={() => toggleConfirmDialog(false)}>
              Cancel
            </Button>
            <Button
              loading={loading}
              onClick={() => onConfirm().then(() => toggleConfirmDialog(false))}
            >
              {confirmButtonLabel || 'Confirm'}
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default ConfirmButton;
