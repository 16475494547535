import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';

import { FormInput, FormPanel } from 'components/FormPanel';

const CREATE_SHARE_VALUATION_CHANGE_MUTATION = gql(`
  mutation CreateShareValuationChange(
    $companyId: ID!
    $shareClassIds: [ID!]!
    $pricePerShare: Float!
    $effectiveFrom: String!
  ) {
    createShareValuationChange(
      companyId: $companyId
      shareClassIds: $shareClassIds
      pricePerShare: $pricePerShare
      effectiveFrom: $effectiveFrom
    ) {
      id
      pricePerShare
      effectiveFrom
      createdAt
    }
  }
`);

const CreateShareValuationChangeDialogButton: FC<{
  companyId: string;
  shareClasses: { id: string; name: string }[];
}> = ({ companyId, shareClasses }) => {
  const [showDialog, toggleDialog] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [createShareValuationChange, { loading, error }] = useMutation(
    CREATE_SHARE_VALUATION_CHANGE_MUTATION,
    {
      update(cache, { data }) {
        if (!data) return;
        cache.modify({
          id: cache.identify({
            id: companyId,
            __typename: 'CompanyType',
          }),
          fields: {
            shareValuationChanges(existingShareValuationChangesRef) {
              const newShareValuationChangeNodeRef = cache.writeFragment({
                data: data.createShareValuationChange,
                fragment: gql(`
                fragment NewShareValuationChange on ShareValuationChangeType {
                  id
                  pricePerShare
                  effectiveFrom              
                  createdAt
                }
              `),
              });

              return [...existingShareValuationChangesRef, newShareValuationChangeNodeRef];
            },
          },
        });
      },
      refetchQueries: ['fetchAssetsByCompanyId'],
    }
  );

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Create Share Valuation Change</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Create Share Valuation Change</DialogTitle>
          <DialogSubTitle>
            Fill in the details below to create a new share valuation change
          </DialogSubTitle>
          <FormPanel
            loading={loading}
            error={error}
            onCancel={() => toggleDialog(false)}
            onSubmit={data => {
              createShareValuationChange({
                variables: {
                  companyId,
                  shareClassIds: data.shareClassIds,
                  pricePerShare: data.pricePerShare,
                  effectiveFrom: data.effectiveFrom,
                },
              }).then(() => toggleDialog(false));
            }}
            buttonRef={buttonRef}
          >
            <FormInput
              type="checkbox-group"
              fieldName="shareClassIds"
              label="Share Classes"
              defaultValue={''}
              options={shareClasses.map(({ id, name }) => ({ value: id, label: name }))}
              validators={{ required: true }}
            />
            <FormInput
              type="currency"
              fieldName="pricePerShare"
              label="Price Per Share"
              defaultValue={''}
              validators={{ required: true }}
            />
            <FormInput
              type="date"
              fieldName="effectiveFrom"
              label="Effective From"
              defaultValue={''}
              validators={{ required: true }}
            />
          </FormPanel>
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default CreateShareValuationChangeDialogButton;
